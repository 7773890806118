import axiosInstance from "../../Helper/axiosInstance";

export default function DBConversations() {
    var baseURL = process.env.REACT_APP_BASE_URL_V4
    function List(from, limit) {

        return new Promise((success, reject) => {

            axiosInstance.get(`${baseURL}/admin/conversations/${from}/${limit}`)
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    return {
        List
    }
}