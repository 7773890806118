import React, { useState, useEffect } from 'react'
import Daily from '../Charts/Daily'
import Monthly from '../Charts/Monthly'
import Weekly from '../Charts/Weekly'
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DataCenter from '../../../Database/DataCenter';
import Varmi from '../../../Helper/Varmi';

function Reports() {
    const [varben, setVarben] = useState();
    const [message, setMessage] = useState();
    const [user, setUser] = useState();

    const [categories, setCategories] = useState([]);
    const [userData, setUserData] = useState([]);
    const [varbenData, setVarbenData] = useState([]);
    const [messageData, setMessageData] = useState([]);
    const [userId, setUserId] = useState("user");


    const [varbenSelected, setVarbenSelected] = useState(false);
    const [userSelected, setUserSelected] = useState(true);
    const [messageSelected, setMessageSelected] = useState(false);

    const [reports, setReports] = useState();

    const defaultFrom = {
        year: 2022,
        month: 1,
        day: 1
    }
    const defaultTo = {
        year: 2022,
        month: 1,
        day: 9
    }
    const [selectedDayRange, setSelectedDayRange] = useState(
        {
            from: defaultFrom,
            to: defaultTo
        }
    );
    const formatInputValue = () => {
        if (selectedDayRange.to == null || selectedDayRange.from == null) return '';
        return selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day + ">" + selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
    };


    useEffect(() => {
        let mounted = true;
        if (mounted && selectedDayRange.from !== undefined && selectedDayRange.from !== null && selectedDayRange.to !== undefined && selectedDayRange.to !== null) {
            let beginDate = JSON.stringify(selectedDayRange.from.year) + "-" + JSON.stringify(selectedDayRange.from.month) + "-" + JSON.stringify(selectedDayRange.from.day);
            let endDate = JSON.stringify(selectedDayRange.to.year) + "-" + JSON.stringify(selectedDayRange.to.month) + "-" + JSON.stringify(selectedDayRange.to.day);

            DataCenter().Manager.Reports.List("service", beginDate, endDate).then(res => {
                mounted && setVarben(res)
            })
            DataCenter().Manager.Reports.List("user", beginDate, endDate).then(res => {
                mounted && setUser(res)
            })
            DataCenter().Manager.Reports.List("message", beginDate, endDate).then(res => {
                mounted && setMessage(res);
            })
        }
        return () => {
            mounted = false;
        };
    }, [selectedDayRange.from, selectedDayRange.to]);

    useEffect(() => {
        setUserData([]);
        let bashData = categories;
        if (userSelected === true || userData === []) {
            let data = userData;
            user?.length && user?.map(item => {
                bashData.push(item?.time?.slice(0, 10));
                setUserData([...userData, item.created]);
            });
        }
        setCategories(bashData);
    }, [userSelected, user]);

    useEffect(() => {
        setMessageData([]);
        if (messageSelected || messageData === []) {
            let data = messageData;
            message?.length && message?.map(item => {
                data.push(item.created);
            })
            setMessageData([...messageData]);
        }
    }, [messageSelected, message]);

    useEffect(() => {
        setVarbenData([]);
        if (varbenSelected === true || varbenData === []) {
            let data = varbenData;
            varben?.length && varben?.map(item => {
                data.push(item.created);
            })
            setVarbenData([...varbenData]);
        }
    }, [varbenSelected, varben]);

    return (
        <div className="">
            {/*begin::Page title*/}
            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                    {/*begin::Heading*/}
                    <h1 className="d-flex flex-column text-dark fw-bolder my-0 fs-1">Kontrol Paneli</h1>
                    {/*end::Heading*/}
                    {/*begin::Breadcrumb*/}
                    <ul className="breadcrumb breadcrumb-dot fw-bold fs-base my-1">
                        <li className="breadcrumb-item text-muted">
                            <a href="/metronic8/demo3/../demo3/index.html" className="text-muted">Ana sayfa</a>
                        </li>
                    </ul>
                </div>
                <div className="d-flex d-lg-none align-items-center ms-n2 me-2">
                    <div className="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle" onClick={()=>{ Varmi.openProfile() }}>
                        <span className="svg-icon svg-icon-1 mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                            </svg>
                        </span>
                    </div>
                    
                </div>
            <div className="row">


                <div className="col-xl-4">
                    <div className="card mb-8">

                        <div className='card-body'>
                            <div className="col-lg-12">
                                <label className="fs-6 form-label fw-bolder text-dark">
                                    Tarih Filtresi
                                </label>
                                <DatePicker
                                    value={selectedDayRange}
                                    onChange={setSelectedDayRange}
                                    inputPlaceholder="Tarih Aralığı Seç.."
                                    formatInputText={formatInputValue}
                                    calendarPopperPosition="bottom"
                                    wrapperClassName="form-control form-control form-control-solid"
                                    inputClassName="form-control form-control form-control-solid p-0"
                                    shouldHighlightWeekends="true"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="card mb-8">
                        <div className='card-body'>
                            <div className="mb-0 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                <label className="d-flex align-items-center form-label mb-5">Veri Blokları
                                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title data-bs-original-title="Monthly billing will be based on your account plan" aria-label="Monthly billing will be based on your account plan" /></label>
                                <div className="mb-0">
                                    <label className="d-flex flex-stack mb-5 cursor-pointer">
                                        <span className="d-flex align-items-center me-2">
                                            <span className="symbol symbol-50px me-6">
                                                <span className="symbol-label">
                                                    <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                            <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black" />
                                                            <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                </span>
                                            </span>
                                            <span className="d-flex flex-column">
                                                <span className="fw-bolder text-gray-800 text-hover-primary fs-5">Kullanıcıları</span>
                                                <span className="fs-6 fw-bold text-muted">Kullanıcıya Ait verileri gösterir</span>
                                            </span>
                                        </span>
                                        <span className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input" type="checkbox" defaultChecked={userSelected} onChange={() => { setUserSelected(!userSelected) }} />
                                        </span>
                                    </label>
                                    <label className="d-flex flex-stack mb-5 cursor-pointer">
                                        <span className="d-flex align-items-center me-2">
                                            <span className="symbol symbol-50px me-6">
                                                <span className="symbol-label">
                                                    <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                            <path d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z" fill="black" />
                                                            <path opacity="0.3" d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                </span>
                                            </span>
                                            <span className="d-flex flex-column">
                                                <span className="fw-bolder text-gray-800 text-hover-primary fs-5">Varben Dataları</span>
                                                <span className="fs-6 fw-bold text-muted">Varbenlere ait dataları gösterir</span>
                                            </span>
                                        </span>
                                        <span className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input" type="checkbox" onChange={() => { console.log(varbenSelected); setVarbenSelected(!varbenSelected) }} />
                                        </span>
                                    </label>
                                    <label className="d-flex flex-stack mb-0 cursor-pointer">
                                        <span className="d-flex align-items-center me-2">
                                            <span className="symbol symbol-50px me-6">
                                                <span className="symbol-label">
                                                    <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                            <path d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z" fill="black" />
                                                            <path opacity="0.3" d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z" fill="black" />
                                                            <path opacity="0.3" d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                </span>
                                            </span>
                                            <span className="d-flex flex-column">
                                                <span className="fw-bolder text-gray-800 text-hover-primary fs-5">Mesajlajma Dataları</span>
                                                <span className="fs-6 fw-bold text-muted">Mesajların atılma tarihlerine göre dataları verir</span>
                                            </span>
                                        </span>
                                        <span className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input" type="checkbox" onChange={() => { console.log(messageSelected); setMessageSelected(!messageSelected) }} />
                                        </span>
                                    </label>
                                </div>
                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                        </div>
                    </div>

                </div>
                <div className="col-xl-8">
                    <div className="card" >
                        <div className="card-body">
                            <Monthly xaxis={categories} userData={userData} varben={varbenData} message={messageData} varbenSelected={varbenSelected} userSelected={userSelected} messageSelected={messageSelected} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Reports