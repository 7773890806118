
/**
 * @param {Object} props Somebody's name.
 * @param {Array} props.dataList Somebody's name.
 */
export default function EmptyArea(props) {
    return <>
        {
            (props.dataList == undefined || props.dataList.length) == 0 ? <>
                <div className="w-100">
                    <div className="d-flex justify-content-center">
                        <img src="https://preview.keenthemes.com/metronic8/demo3/assets/media/svg/illustrations/easy/6.svg" style={{ maxHeight: 200 }} />

                    </div>
                    <h3 className="card-title align-items-center flex-column mt-6 text-center">
                        <div className="card-label fw-bolder text-dark">Wow! Bekleyen bir hizmet yok</div>
                        <div className="text-gray-400 mt-1 fw-bold fs-6">Görünen oki sen bu işi çok iyi yapıyorsun ;)</div>
                    </h3>
                </div>

            </> : props.children
        }
    </>
}