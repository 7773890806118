import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DataCenter from "../../../Database/DataCenter";
import EmptyArea from "../../../Helper/EmptyArea";
import Pagination from "react-js-pagination";
import Alert from '../../../Template/Helper/Alert'
import ContentLoader from "react-content-loader";



export default function UsersList() {
    const [contentLoading, setContentLoading] = useState(false);
    const [users, setUsers] = useState({});

    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(0);
    const [stateLimit, setLimit] = useState(5);
    const [stateProfileType, setProfileType] = useState();
    const [stateBeginDate, setBeginDate] = useState();
    const [stateEndDate, setEndDate] = useState();
    const [stateName, setStateName] = useState();
    const [statePhone, setStatePhone] = useState();
    const [searchName, setSearchName] = useState({});

    useEffect(() => {
        setContentLoading(true);
        let mounted = true;
        var name = searchParams.get("name");
        var beginDate = searchParams.get("beginDate");
        var endDate = searchParams.get("endDate");
        var page = searchParams.get("page");
        var limit = searchParams.get("limit");
        var profileType = searchParams.get("profileType");
        var phone = searchParams.get("phone");

        if (limit == null || page == null || profileType == null) {

            GenerateUrl()
            return;

        }
        setLimit(limit)
        setStateName(name)
        setPage(page)
        setProfileType(profileType)
        setStatePhone(phone)

        DataCenter().Manager.User.List(page, limit, name, beginDate, endDate, phone, profileType).then(r => (
            r !== "" ? setContentLoading(false) : "",
            mounted && setUsers(r)
        ))
        // GetData(page, limit, name, isActive, beginDate, endDate, phone, profileType)

        return () => {
            mounted = false;
        }
    }, [searchParams])



    function ChangePagination(e) {
        setPage((parseInt(e) - 1))
    }
    useEffect(() => {
        var urlPage = searchParams.get("page");
        if (urlPage != null && page != urlPage && page != 0) {
            GenerateUrl();
        }
    }, [page])



    // function GetData(page, limit, name, isActive, beginDate, endDate, phone, profileType) {
    //     DataCenter().Manager.User.List(page, limit, name, beginDate, endDate, phone, profileType).then(r => {
    //         setUsers(r)
    //     })
    // }


    function GenerateUrl() {
        var name = searchParams.get("name");
        var isActive = searchParams.get("isActive");
        var beginDate = searchParams.get("beginDate");
        var endDate = searchParams.get("endDate");
        var localPage = searchParams.get("page");
        var limit = searchParams.get("limit");
        var profileType = searchParams.get("profileType");
        var phone = searchParams.get("phone");


        var myArray = {
            name: stateName == name ? name : stateName,
            isActive: isActive,
            beginDate: stateBeginDate == beginDate ? beginDate : stateBeginDate,
            endDate: stateEndDate == endDate ? endDate : stateEndDate,
            page: page == localPage ? localPage : page,
            limit: stateLimit == limit ? limit : stateLimit,
            profileType: stateProfileType == profileType ? profileType : stateProfileType,
            phone: statePhone == phone ? phone : statePhone,
        };
        var myURL = "";
        Object.keys(myArray).map(r => {

            if (r == "limit") {
                myURL += r + "=" + (myArray[r] ?? "5") + "&";
            } else if (r == "page") {
                myURL += r + "=" + (myArray[r] ?? "0") + "&";
            } else if (r == "profileType") {
                myURL += r + "=" + (myArray[r] ?? "all") + "&";
            }
            else {
                if (myArray[r] != null) {
                    myURL += r + "=" + (myArray[r]) + "&";
                }

            }
        })

        history('/admin/user?' + myURL)



    }

    function ChangePageLimit(value) {
        setLimit(value)
    }


    function Find() {

        GenerateUrl()

    }

    function ChangeProfileType(e) {
        setProfileType(e.target.value)
    }

    function DeleteUser(id) {
        Alert().Question("Silmek istiyor musunuz?").then(res => {
            if (res) {
                // DataCenter().Manager.User.Delete(id).then(res => {

                // })
            }
        })

    }

    return (
        <div>
            <div className="card mb-7">
                <div className="card-body">
                    <div id="kt_advanced_search_form">
                        <div className="row g-8 mb-8">
                            <div className="col-xxl-7">
                                <label className="fs-6 form-label fw-bolder text-dark">
                                    İsime Göre Arama
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="position-relative w-100">
                                        <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x="17.0365"
                                                    y="15.1223"
                                                    width="8.15546"
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(45 17.0365 15.1223)"
                                                    fill="black"
                                                />
                                                <path
                                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid ps-10"
                                            name="search"
                                            defaultValue=""
                                            value={stateName}
                                            onChange={(e) => { setStateName(e.target.value) }}
                                            placeholder="İsim"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-5">
                                <div className="row g-8">
                                    <div className="col-lg-6">
                                        <label className="fs-6 form-label fw-bolder text-dark">
                                            Listeleme Limiti
                                        </label>

                                        <select
                                            className="form-select form-select-solid "
                                            data-control="select2"
                                            data-placeholder="In Progress"
                                            data-hide-search="true"
                                            data-select2-id="select2-data-7-zqzm"
                                            defaultValue={stateLimit}
                                            onChange={(e) => { ChangePageLimit(e.target.value) }}
                                            tabIndex={-1}

                                        >

                                            <option value={5} selected={stateLimit == 5}>5</option>
                                            <option value={10} selected={stateLimit == 10}>10</option>
                                            <option value={20} selected={stateLimit == 20}>20</option>
                                            <option value={50} selected={stateLimit == 50}>50</option>
                                            <option value={100} selected={stateLimit == 100}>100</option>

                                        </select>


                                    </div>
                                    <div className="col-lg-6">
                                        <label className="fs-6 form-label fw-bolder text-dark">
                                            Profil Tipi
                                        </label>
                                        <div className="nav-group nav-group-fluid">
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="type"
                                                    defaultValue="all"
                                                    defaultChecked={stateProfileType == "all" ? "checked" : null}
                                                    onChange={(e) => { ChangeProfileType(e) }}

                                                />
                                                <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                                                    Hepsi
                                                </span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="type"
                                                    onChange={(e) => { ChangeProfileType(e) }}
                                                    defaultValue="varben"
                                                    defaultChecked={stateProfileType == "varben" ? "checked" : null}
                                                />
                                                <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                                                    Varben
                                                </span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="type"
                                                    onChange={(e) => { ChangeProfileType(e) }}
                                                    defaultChecked={stateProfileType == "users" ? "checked" : null}
                                                    defaultValue="users"
                                                />
                                                <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                                                    Kullanıcı
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-8">
                            <div className="col-xxl-7">
                                <div className="row g-8">
                                    <div className="col-lg-4">
                                        <label className="fs-6 form-label fw-bolder text-dark">
                                            Başlangıç-Bitiş Tarihi
                                        </label>
                                        <Example onDone={(from, to) => {
                                            setBeginDate(from);
                                            setEndDate(to)
                                        }} />
                                    </div>

                                    <div className="col-lg-4">
                                        <label className="fs-6 form-label fw-bolder text-dark">
                                            Telefon
                                        </label>
                                        <input
                                            type="text"
                                            value={statePhone}
                                            onChange={(e) => { setStatePhone(e.target.value) }}
                                            className="form-control form-control form-control-solid"
                                            name="city"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="fs-6 form-label fw-bolder text-dark">
                                            Team Size
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control form-control-solid"
                                            name="city"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-5">
                                <div className="row g-8">
                                    <div className="col-lg-6">
                                        <label className="fs-6 form-label fw-bolder text-dark">
                                            Üyelik Durumu
                                        </label>
                                        <div className="form-check form-switch form-check-custom form-check-solid mt-1">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue=""
                                                id="flexSwitchChecked"
                                                defaultChecked="checked"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="flexSwitchChecked"
                                            >
                                                Aktif
                                            </label>
                                        </div>

                                    </div>
                                    <div className="col-lg-6" style={{ position: 'relative', bottom: '-25px', margin: 'auto' }}>
                                        <div className="btn btn-primary w-100" onClick={() => { Find() }}>Ara</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-flush overflow-hidden w-initial " >
                <div className="card-header pt-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">Kullanıcı Listesi</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">Sistemde kayıtlı toplam {users.totalItemCount} kullanıcı bulunmaktadır.</span>
                    </h3>
                    {/*end::Title*/}
                    {/* <div className="card-toolbar">
                        <NavLink to="/admin/user/insert" className="btn btn-primary font-weight-bolder">
                            <span className="svg-icon svg-icon-md">

                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <circle fill="#000000" cx={9} cy={15} r={6} />
                                        <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                            </span>Kullanıcı oluştur</NavLink>
                    </div> */}


                </div>


                <div className="card-body">
                    <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer" >
                        <div className="table-responsive">
                            <EmptyArea dataList={users?.data}>
                                {contentLoading ? <ContentLoader
                                    width={1500}
                                    height={400}
                                    viewBox="0 0 1500 400"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
                                    <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
                                    <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
                                    <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
                                    <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
                                    <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
                                    <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
                                    <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
                                    <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
                                    <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
                                    <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
                                    <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
                                    <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
                                    <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="1456" cy="203" r="12" />
                                    <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
                                    <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
                                    <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
                                    <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
                                    <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
                                    <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
                                    <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="1456" cy="265" r="12" />
                                    <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
                                    <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
                                    <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
                                    <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
                                    <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
                                    <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
                                    <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="1456" cy="323" r="12" />
                                    <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
                                    <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
                                    <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
                                    <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
                                    <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
                                    <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
                                    <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="1456" cy="386" r="12" />
                                    <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
                                    <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
                                    <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
                                    <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
                                    <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
                                    <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="37" cy="97" r="11" />
                                    <rect x="26" y="23" rx="5" ry="5" width="153" height="30" />
                                    <circle cx="1316" cy="88" r="11" />
                                    <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
                                    <circle cx="77" cy="96" r="11" />
                                </ContentLoader> :
                                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users"  >
                                        <thead>
                                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                                <th
                                                    tabIndex={0}
                                                    aria-controls="kt_table_users"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                >
                                                    ID
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="kt_table_users"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                >
                                                    Photo
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="kt_table_users"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="kt_table_users"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                >
                                                    Hizmet Sayısı
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="kt_table_users"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="kt_table_users"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                >
                                                    Phone
                                                </th>
                                                <th className="text-center " rowSpan={1} colSpan={1}>
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-gray-600 fw-bold">
                                            {users?.data?.length ? users?.data.map((user, index) => (
                                                <tr className="odd" key={index}>
                                                    <td>{user.userID}</td>
                                                    <td className="d-flex align-items-center">


                                                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                            <a href="">
                                                                <div className="symbol-label">
                                                                    <img
                                                                        src={user.photoPath != null ? "https://3.72.47.28:3000/" + user.photoPath : "https://3.72.47.28:3000/upload/user-6/user/profile/image-profileFile-6-e2bab815-9f17-4d6e-8401-95e02a55af84.jpg"}
                                                                        alt={user.fullName}
                                                                        className="w-100"
                                                                    />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <NavLink
                                                            to={"/admin/user/" + user.userID}
                                                            style={{ color: "black" }}
                                                        >
                                                            <div className="d-flex flex-column">{user.fullName}</div>
                                                        </NavLink>
                                                    </td>
                                                    <td>{user.serviceCount}</td>
                                                    <td>{user.email}</td>
                                                    <td>
                                                        <div>{user.phone}</div>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-light btn-active-light-primary btn-sm"
                                                            data-kt-menu-trigger="click"
                                                            data-kt-menu-placement="bottom-end"
                                                        >
                                                            Actions
                                                            <span className="svg-icon svg-icon-5 m-0">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                                                        fill="black"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                        <div
                                                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                                                            data-kt-menu="true"
                                                        >
                                                            <div className="menu-item px-3">
                                                                <a href="" className="menu-link px-3">
                                                                    Edit
                                                                </a>
                                                            </div>
                                                            <div className="menu-item px-3">
                                                                <a
                                                                    onClick={() => DeleteUser(user.userID)}
                                                                    className="menu-link px-3"
                                                                    data-kt-users-table-filter="delete_row"
                                                                >
                                                                    Delete
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : ""}
                                        </tbody>
                                    </table>
                                }
                            </EmptyArea>

                        </div>

                    </div>


                    {

                    }

                    <Pagination
                        activePage={(parseInt(page) + 1)}
                        itemsCountPerPage={(stateLimit)}
                        totalItemsCount={users.totalItemCount}
                        pageRangeDisplayed={5}
                        itemClass={"paginate_button page-item"}
                        innerClass={"pagination"}
                        linkClass={"page-link"}
                        activeClass={" active"}
                        itemClassPrev={"previous"}
                        prevPageText={<i className="previous"></i>}
                        nextPageText={<i className="next"></i>}
                        activeLinkClass={""}
                        onChange={(e) => { ChangePagination(e) }}
                    />



                </div>




            </div>

        </div>
    );
}

const Example = (props) => {
    const defaultFrom = {
        year: 2019,
        month: 1,
        day: 1,
    };
    const defaultTo = {
        year: 2022,
        month: 12,
        day: 1,
    };
    const defaultValue = {
        from: defaultFrom,
        to: defaultTo,
    };
    const [selectedDayRange, setSelectedDayRange] = useState(
        defaultValue
    );

    useEffect(() => {
        var from;
        var to;
        if (selectedDayRange.to == null || selectedDayRange.from == null) {

        }
        else {
            from = selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
            to = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
            props.onDone(from, to)
        }

    }, [selectedDayRange])
    const formatInputValue = () => {
        console.log(selectedDayRange.to)
        if (selectedDayRange.to == null || selectedDayRange.from == null) return '';
        return selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day + ">" + selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
    };
    return (
        <DatePicker
            value={selectedDayRange}
            onChange={setSelectedDayRange}
            inputPlaceholder="Tarih Aralığı Seç.."
            formatInputText={formatInputValue}
            calendarPopperPosition="bottom"
            wrapperClassName="form-control form-control form-control-solid"
            inputClassName="form-control form-control form-control-solid p-0"
            shouldHighlightWeekends="true"
        />
    );
};
