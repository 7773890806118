

import axiosInstance from "../../Helper/axiosInstance";


export default function DBService() {
  var baseURL = process.env.REACT_APP_BASE_URL_V4
  function List(from, limit, name, status, category, beginDate, endDate) {

    var myArray = []

    if (status != undefined && status != null && status != "") {
      myArray.push({
        key: "status",
        data: status,
      })
    }
    if (category != undefined && category != null && category != "") {
      myArray.push({
        key: "category",
        data: category,
      })
    }
    if (name != undefined && name != null && name != "") {
      myArray.push({
        key: "name",
        data: name,
      })
    }
    if (beginDate != undefined && beginDate != null && beginDate != "") {
      myArray.push({
        key: "beginDate",
        data: beginDate,
      })
    }
    if (endDate != undefined && endDate != null && endDate != "") {
      myArray.push({
        key: "endDate",
        data: endDate,
      })
    }

    var query = myArray.map((item) => {
      return item.key + "=" + item.data;
    }).join("&");





    return new Promise((success, reject) => {
      axiosInstance.get(`${baseURL}/admin/services/${from}/${limit}?${query}`)
        .then(function (response) {
          success(response?.data);
        })
        .catch(function (error) {
          reject(error);
        })
    });
  }
  function Delete(id) {
    return new Promise((success, reject) => {
      axiosInstance.delete(`${baseURL}/admin/services/${id}`)
        .then(function (response) {
          success(response.data)
        })
        .catch(function (error) {
          reject(error)
        })
    });
  }

  function Detail(id) {
    return new Promise((success, reject) => {
      axiosInstance.get(`${baseURL}/admin/services/${id}`)
        .then(function (response) {
          success(response?.data)
        })
        .catch(function (error) {
          reject(error)
        })
    });
  }

  function Update(id, status) {
    return new Promise((success, reject) => {
      axiosInstance.put(`${baseURL}/admin/services/${id}/${status}`)
        .then(function (response) {
          success(response?.data)
        })
        .catch(function (error) {
          reject(error)
        })
    });
  }

  function PhotoConfirmation(id, status) {
    return new Promise((success, reject) => {
      axiosInstance.put(`${baseURL}/admin/services/${id}/${status}`)
        .then(function (response) {
          success(response?.data)
        })
        .catch(function (error) {
          reject(error)
        })
    });
  }

  return {
    List, Delete, Detail, PhotoConfirmation, Update
  }
}