import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import DataCenter from '../../../Database/DataCenter'
import ServiceMessageList from './ServiceMessageList'
import ServiceNotificationList from './ServiceNotificationList'
import ServiceOverview from './ServiceOverview'
import ServicePaymentList from './ServicePaymentList'
import ServiceTroubleList from './ServiceTroubleList'
import Alert from '../../../Template/Helper/Alert'


function ServiceDetail() {

    const [state, setState] = useState(4)
    const [serviceDetail, setServiceDetail] = useState()
    const [userDetail, setUserDetail] = useState();
    const [messageDetail, setMessageDetail] = useState();
    const [imageData, setImageData] = useState([]);
    const [profile, setProfile] = useState()
    const [services, setServices] = useState()
    const [notification, setNotification] = useState()
    const [conversation, setConversation] = useState()
    const [serviceUpdateRes, setServiceUpdateRes] = useState();

    const { id } = useParams();

    useEffect(() => {
        let mounted = true;
        DataCenter().Manager.Services.Detail(id).then(r => {
            if (mounted) {
                setImageData(r.imageData);
                setServiceDetail(r.serviceDetail);
                setUserDetail(r.userDetail);
                setMessageDetail(r.messageDetail);
            }
        });
        return () => {
            mounted = false;
        }
    }, [id])

    function ApproveService(id) {
        Alert().Question("Onaylamak istediğinize emin misiniz?").then(res => {
            if (res) {
                DataCenter().Manager.Services.Update(id, "Approved").then(res => {
                    if (res.code === 1) {
                        Alert().Success("Güncelleme işlemi başarılı");
                        setServiceUpdateRes(true);
                    }
                })
            }
        })
    }

    function RejectService(id) {
        Alert().Question("Reddetmek istediğinize emin misiniz?").then(res => {
            if (res) {
                DataCenter().Manager.Services.Update(id, "Rejected").then(res => {
                    if (res.code === 1) {
                        Alert().Success("Güncelleme işlemi başarılı");
                        setServiceUpdateRes(true);
                    }
                })
            }
        })
    }
    return (
        <div>
            <div className="card mb-5 mb-xxl-8">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <div className="me-7 mb-4">
                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                <img
                                    src={(serviceDetail?.servicesImages == null ? "https://3.72.47.28:3000/upload/user-6/user/profile/image-profileFile-6-e2bab815-9f17-4d6e-8401-95e02a55af84.jpg" : "https://3.72.47.28:3000/" + serviceDetail?.servicesImages[0])}
                                    alt="image"

                                />
                                <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px" />
                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <a
                                            href="#"
                                            className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
                                        >
                                            {
                                                serviceDetail?.description
                                            }
                                        </a>
                                        <a href="#">
                                            <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                                                        fill="#00A3FF"
                                                    />
                                                    <path
                                                        className="permanent"
                                                        d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </span>
                                        </a>

                                    </div>
                                </div>
                                {
                                    serviceDetail?.verify === "Undefined" || serviceDetail?.verify === "Pending" ?
                                        <div>
                                            <div className='btn btn-success btn-sm' onClick={() => ApproveService(serviceDetail.serviceID)}>Onayla</div>
                                            <div className='btn btn-danger btn-sm' onClick={() => RejectService(serviceDetail.serviceID)}>Reddet</div>
                                        </div>
                                        : serviceDetail?.verify === "Approved" || serviceDetail?.verify === "Rejected" && ""
                                }
                            </div>

                            <div className="row p-0 mb-5 px-9">
                                <div className="col">
                                    <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                        <span className="fs-4 fw-bold text-success d-block">Mesaj Sayısı</span>
                                        <span
                                            className="fs-2hx fw-bolder text-gray-900 counted"
                                            data-kt-countup="true"
                                            data-kt-countup-value={36899}
                                        >
                                            {messageDetail?.length}
                                        </span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                        <span className="fs-4 fw-bold text-primary d-block">Hizmeti Alan Kişi Sayısı</span>
                                        <span
                                            className="fs-2hx fw-bolder text-gray-900 counted"
                                            data-kt-countup="true"
                                            data-kt-countup-value={72}
                                        >
                                            ---
                                        </span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                        <span className="fs-4 fw-bold text-danger d-block">Yayınladığı Hizmetler</span>
                                        <span
                                            className="fs-2hx fw-bolder text-gray-900 counted"
                                            data-kt-countup="true"
                                            data-kt-countup-value={291}
                                        >
                                            ---
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                        <li className="nav-item mt-2">
                            <a
                                className={"nav-link text-active-primary ms-0 me-10 py-5 " + (state === 4 ? 'active' : "")}
                                onClick={() => { setState(4) }}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fa-solid fa-house" style={{ fontSize: "18px" }}></i>&nbsp;
                                Overview
                            </a>
                        </li>
                        <li className="nav-item mt-2">
                            <a
                                className={"nav-link text-active-primary ms-0 me-10 py-5 " + (state === 0 ? 'active' : "")}
                                onClick={() => { setState(0) }}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fa-solid fa-envelope" style={{ fontSize: "18px" }}></i>&nbsp;
                                Mesajlar
                            </a>
                        </li>
                        <li className="nav-item mt-2">
                            <a
                                className={"nav-link text-active-primary ms-0 me-10 py-5 " + (state === 1 ? 'active' : "")}
                                onClick={() => { setState(1) }}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fa-solid fa-circle-exclamation" style={{ fontSize: "18px" }}></i>&nbsp;
                                Şikayet ve Sorunlar
                            </a>
                        </li>
                        <li className="nav-item mt-2">
                            <a
                                className={"nav-link text-active-primary ms-0 me-10 py-5 " + (state === 2 ? 'active' : "")}
                                onClick={() => { setState(2) }}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fa-solid fa-bell" style={{ fontSize: "18px" }}></i>&nbsp;
                                Bildirim
                            </a>
                        </li>
                        <li className="nav-item mt-2">
                            <a
                                className={"nav-link text-active-primary ms-0 me-10 py-5 " + (state === 3 ? 'active' : "")}
                                onClick={() => { setState(3) }}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fa-solid fa-credit-card" style={{ fontSize: "18px" }}></i>&nbsp;
                                Ödeme Geçmişi
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {(
                state === 0 ? <ServiceMessageList messages={messageDetail} /> :
                    state === 1 ? <ServiceTroubleList /> :
                        state === 2 ? <ServiceNotificationList /> :
                            state === 3 ? <ServicePaymentList /> :
                                state === 4 ? <ServiceOverview userDetail={userDetail} serviceDetail={serviceDetail} imageData={imageData} /> : "")
            }


        </div>
    )
}

export default ServiceDetail