import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function Complain(data) {

    const [success, setSuccess] = useState()

    useEffect(() => {


        setSuccess({
            options: {
                
                chart: {
                    sparkline: {
                        enabled: false // only lines will be shown
                    },
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 20,
                        left: 0,
                        blur: 4,
                        color: '#000',
                        opacity: 0.05
                    },
                    toolbar: {
                        show: false // top-right icons 
                    },
                },
                colors: ['#775DD0'],


                grid: {
                    strokeDashArray: 3,
                },
                stroke: {
                    curve: 'smooth',
                    width: 3,

                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        columnWidth: '40%',
                    }
                },
            },
            series: [
                {
                    name: "series-1",
                    data: data.data
                },
            ]
        })
    }, [data])
    return <>

        {
            success == undefined ? "" : <Chart
                options={success.options}
                series={success.series}
                type="bar"
                height="100%"
                width="100%"
            />
        }

    </>

}

