import React from 'react'

function LoadingButton({ buttonText, className, loading, onClick, icon, id, status }) {
  return (
    <button disabled={loading} type="button" className={className} id="load2" onClick={() => status === "" || status === undefined ? onClick(id) : onClick(id, status)}>
      {loading && (
        <i
          className="fa fa-refresh fa-spin"
          style={{ marginRight: "5px" }}
        />
      )}
      {loading && <span>İşlem gerçekleştiriliyor.</span>}
      {!loading && <span>{buttonText}{icon}</span>}
    </button>
  )
}

export default LoadingButton