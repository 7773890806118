import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function Monthly({ xaxis, userData, varben, message, userSelected, varbenSelected, messageSelected }) {

    const [settings, setSettings] = useState({
        chart: {
            stacked: false,
            sparkline: {
                enabled: true // only lines will be shown
            },
            dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 20,
                left: 0,
                blur: 4,
                color: '#000',
                opacity: 0.05
            }
        },
        colors: ['#1170e4'],
        // fill: {
        //     type: "gradient",
        //     gradient: {
        //         shadeIntensity: 1,
        //         opacityFrom: 0.7,
        //         opacityTo: 0.9,
        //         stops: [0, 90, 100]
        //     }
        // },

        // xaxis: {
        //     type: 'datetime',
        //     categories: xaxis,
        // },
        stroke: {
            curve: 'smooth',
            width: 4,

        }
    })
    const [data, setData] = useState({});
    useEffect(() => {
        data.userData = userData;
        data.varben = varben;
        data.message = message;
        setData(JSON.parse(JSON.stringify(data)));
    }, [xaxis, userData, varben, message]);

    return <>
        {
            xaxis == undefined ? "" : <Chart
                options={settings}
                series={[
                    {
                        data: data.userData === undefined ? [] : data.userData,
                        color: "#23cf31"
                    },
                    {
                        data: data.varben === undefined ? [] : data.varben,
                        color: "#fccf03"
                    },
                    {
                        data: data.message === undefined ? [] : data.message,
                        color: "#42c5f5"
                    }
                ]}
                type="line"
                width="100%"
                height="460"
            />
        }

    </>
}