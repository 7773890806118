import React from 'react'
import { Route, BrowserRouter as Router, Switch, Routes } from 'react-router-dom';

import Authorization from '../Views/Authorization/Authorization';
import Admin from '../Views/Main/Admin';


function Index() {
  return (

    <Router basename="/">
      <Routes>

        <Route exact path='/admin/*' element={<Admin />} />


        <Route exact path="/*" element={<Authorization />}>
        </Route>
      </Routes>
    </Router>

  )
}

export default Index