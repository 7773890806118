import React from 'react';
import ReactDOM from 'react-dom';
import "./Template/css/admin.css"
import "./Template/css/admin2.css"

import Index from './Template';



ReactDOM.render(
  <React.Fragment>
    <Index />
  </React.Fragment>,
  document.getElementById('root')
);