import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";


export default function Changers(data) {
    const [success, setSuccess] = useState()

    useEffect(() => {
        setSuccess({
            options: {
                chart: {
                    sparkline: {
                        enabled: false // only lines will be shown
                    },
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 0,
                        left: 10,
                        blur: 5,
                        color: '#000',
                        opacity: 0.05
                    },
                    toolbar: {
                        show: false // top-right icons 
                    },

                },
                colors: [
                    function ({ value, seriesIndex, dataPointIndex, w }) {
                        if (value > 40) {
                            return "#FFB703";
                        } else {
                            return "#31bda3";
                        }
                    }
                ],
                grid: {
                    strokeDashArray: 3,
                },
                // fill: {
                //     type: "gradient",
                //     gradient: {
                //         shadeIntensity: 1,
                //         opacityFrom: 0.7,
                //         opacityTo: 0.9,
                //         stops: [0, 90, 100]
                //     }
                // },


                stroke: {
                    curve: 'smooth',
                    width: 1,
                    colors: [
                        function ({ value, seriesIndex, dataPointIndex, w }) {
                            if (value > 40) {
                                return "#00BBF2"
                            } else {
                                return "#AAECFF"
                            }
                        }
                    ]

                },
                dataLabels: {
                    enabled: false //shows the amounts on the line for each point
                },
                plotOptions: {
                    bar: {
                        borderRadius: 2,
                        columnWidth: 35,
                    },
                },

            },
            series: [
                {
                    name: "series-1",
                    data: data.data
                },
            ]
        })
    }, [data])
    return <>

        {
            success == undefined ? "" : <Chart
                options={success.options}
                series={success.series}
                type="bar"
                height="100%"
            />
        }

    </>

}

