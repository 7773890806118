import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import DataCenter from '../../../Database/DataCenter';
import EmptyArea from '../../../Helper/EmptyArea';
import Pagination from 'react-js-pagination';

function Page1() {
  var navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [contentLoading, setContentLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [servicesList, setServicesList] = useState([]);
  const [stateLimit, setStateLimit] = useState(1);

  useEffect(() => {
    let mounted = true;

    DataCenter().Manager.Services.List(page, stateLimit, null, "Pending").then(r => (
      r !== "" && mounted ? (setContentLoading(false), setServicesList(r)) : ""
    ))
    return () => {
      mounted = false;
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    setContentLoading(true);
    var fromService = searchParams.get("fromService");

    console.log(fromService);
    setPage(fromService);

    if (fromService == null) {
      navigate("/admin/home/1?fromService=" + (fromService ?? 0))
      return ""
    }
    DataCenter().Manager.Services.List(fromService, stateLimit, null, "Pending").then(r => (
      r !== "" && mounted ? (setContentLoading(false), setServicesList(r)) : ""
    ))
    return () => {
      mounted = false;
    }
  }, [searchParams])

  function ChangePagination(e) {
    // setPage((parseInt(e) - 1))
    navigate("/admin/home/1?fromService=" + (e - 1))
  }
  return (
    <div className='card card-flush h-xl-100'>
      <div className="card-header pt-7 d-flex justify-content-between align-items-center">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark text-capitalize">Onay Bekleyen hizmetler</span>
          <span className="text-gray-400 mt-1 fw-bold fs-6">
            <b>{servicesList?.length}</b> Hizmet onay bekliyor
          </span>
        </h3>
      </div>
      <EmptyArea dataList={servicesList?.data}>
        {contentLoading ? <ContentLoader
          width="65rem"
          height="100%"
          viewBox="0 100 600 500"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
          <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
          <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
          <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
          <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
          <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
          <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
          <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="203" r="12" />
          <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
          <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="265" r="12" />

          <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
          <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="323" r="12" />
          <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
          <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="386" r="12" />
          <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
          <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
          <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
          <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
          <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
          <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
          <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
          <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
          <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
          <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
          <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />



        </ContentLoader> :
          <div className="card-body h-100" style={{ minHeight: "250px" }}>
            <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer" >
              <div className="table-responsive h-100">
                <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer h-100" id="kt_table_widget_4_table" >
                  <thead>
                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                      <th
                        className="min-w-100px sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: 100 }}
                      >
                        Sipariş Id
                      </th>
                      <th
                        className="text-end max-w-20px sorting_disabled"
                        style={{ width: 20 }}
                      >
                        Created
                      </th>
                      <th
                        className="text-end min-w-125px sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: 125 }}
                      >
                        Müşteri
                      </th>
                      <th
                        className="text-end min-w-200px sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: 100 }}
                      >
                        Kategori
                      </th>
                      <th
                        className="text-end min-w-50px sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: "93.125px" }}
                      >
                        Durumu
                      </th>
                      <th
                        className="text-end sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: 25 }}
                      />
                    </tr>
                  </thead>
                  <tbody className="fw-bolder text-gray-600" style={{ minHeight: "250px" }}>


                    {servicesList?.data?.length ? servicesList?.data?.map((service, index) => {

                      if (index < 5) return <tr className="odd" key={index}>
                        <td>
                          <NavLink className="text-dark text-hover-primary" to={"/admin/service/:" + service?.serviceID}>
                            #{service?.serviceID}
                          </NavLink>


                        </td>
                        <td
                          className="text-end"
                          data-order="2022-03-02T10:04:00+03:00"
                        >
                          {service.createDate}
                        </td>
                        <td className="text-end">
                          <a href="" className="text-dark text-hover-primary text-capitalize">
                            {service?.creatorName}
                          </a>
                        </td>
                        <td className="text-end">{service?.categoryName}</td>
                        {/* <td className="text-end">
                                                        <span className="text-dark fw-bolder">$86.70</span>
                                                    </td> */}
                        <td className='text-end' >

                          <span className={"badge fs-7 badge-light-" + (service.status === "Accepted" ? 'success' : 'danger')}>{service.verify}</span>
                        </td>


                      </tr>
                    }) : ""
                    }
                  </tbody>
                </table>
              </div>
              <Pagination
                activePage={(parseInt(page) + 1)}
                itemsCountPerPage={(stateLimit)}
                totalItemsCount={servicesList?.totalItemCount}
                pageRangeDisplayed={5}
                itemClass={"paginate_button page-item"}
                innerClass={"pagination"}
                linkClass={"page-link"}
                activeClass={" active"}
                itemClassPrev={"previous"}
                prevPageText={<i className="previous"></i>}
                nextPageText={<i className="next"></i>}
                activeLinkClass={""}
                onChange={(e) => { ChangePagination(e) }}
              />
            </div>

          </div>
        }
      </EmptyArea>
    </div>
  )
}

export default Page1