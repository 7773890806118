import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { NavLink } from 'react-router-dom';
import logo from '../../../Assets/imgs/img-23.jpg'
/**
 * 
 * @param {Object} props 
 * @param {Object} props.profile 
 * @param {Object} props.services 
 * @param {Object} props.conversation 
 * @returns 
 */
function Overview(props) {

    const [profile, setProfile] = useState()
    const [services, setServices] = useState()
    const [conversation, setConversation] = useState()
    useEffect(() => {
        setProfile(props?.profile);
        setServices(props?.services);
        setConversation(props?.conversation);
        console.log(props.services)
    }, [props.profile, props.services, props.conversation])
    return (
        <div className="row g-6 g-xl-9">
            <div className="col-lg-6">
                <div className="card card-flush h-lg-100">
                    <div className="card-header mt-6">
                        <div className="card-title flex-column">
                            <h3 className="fw-bolder mb-1">Mesaj Grafikleri</h3>
                            <div className="fs-6 fw-bold text-gray-400">10 Overdue Tasks</div>
                        </div>
                        <div className="card-toolbar">

                        </div>
                    </div>
                    <div className="card-body p-9 pt-5">
                        <div className="d-flex flex-wrap">
                            <div className="position-relative d-flex flex-center h-175px w-175px me-15 mb-7">
                                <div className="position-absolute translate-middle start-50 top-50 d-flex flex-column flex-center">
                                    <span className="fs-3 fw-bolder">237</span>
                                    <span className="fs-6 fw-bold text-gray-400">Total Tasks</span>


                                </div>
                                <ApexChart1 data={[services?.length, conversation?.length, 1]} />
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
                                <div className="d-flex fs-6 fw-bold align-items-center mb-3">
                                    <div className="bullet bg-primary me-3" />
                                    <div className="text-gray-400">Verdiği Hizmetler</div>
                                    <div className="ms-auto fw-bolder text-gray-700">{services?.length}</div>
                                </div>
                                <div className="d-flex fs-6 fw-bold align-items-center mb-3">
                                    <div className="bullet bg-success me-3" />
                                    <div className="text-gray-400">Aldığı Hizmetler</div>
                                    <div className="ms-auto fw-bolder text-gray-700">{conversation?.length}</div>
                                </div>
                                <div className="d-flex fs-6 fw-bold align-items-center mb-3">
                                    <div className="bullet bg-danger me-3" />
                                    <div className="text-gray-400">Mesaj</div>
                                    <div className="ms-auto fw-bolder text-gray-700">0</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="card card-flush py-4 flex-row-fluid">
                    <div className="card-header">
                        <div className="card-title">
                            <h2>Banka bilgileri</h2>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="table-responsive">
                            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                <tbody className="fw-bold text-gray-600">
                                    <tr>
                                        <td className="text-muted">
                                            <div className="d-flex align-items-center">
                                                <span className="svg-icon svg-icon-2 me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={20}
                                                        height={21}
                                                        viewBox="0 0 20 21"
                                                        fill="none"
                                                    >
                                                        <path
                                                            opacity="0.3"
                                                            d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                İsim - Soyisim
                                            </div>
                                        </td>
                                        <td className="fw-bolder text-end">{(profile?.bankFirstName ?? "- -") + " " + (profile?.bankLastName ?? "- -")}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted">
                                            <div className="d-flex align-items-center">
                                                <span className="svg-icon svg-icon-2 me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            opacity="0.3"
                                                            d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                IBAN
                                            </div>
                                        </td>
                                        <td className="fw-bolder text-end">{profile?.bankIBAN ?? "- - - -"}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted">
                                            <div className="d-flex align-items-center">
                                                <span className="svg-icon svg-icon-2 me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            opacity="0.3"
                                                            d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                TC ID
                                            </div>
                                        </td>
                                        <td className="fw-bolder text-end">{profile?.authTCID ?? "- - - -"}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted">
                                            <div className="d-flex align-items-center">
                                                <span className="svg-icon svg-icon-2 me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            opacity="0.3"
                                                            d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                Fatura Adresi
                                            </div>
                                        </td>
                                        <td className="fw-bolder text-end">{profile?.bankAddress ?? "- - - -"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div className="row g-10">


                            {
                                props.services?.map(it => {
                                    return <div className="col-md-4">
                                        <NavLink to={`/admin/service/${it.serviceID}`}>
                                            <div className="card-xl-stretch me-md-6">

                                                <a
                                                    className="d-block overlay"
                                                    data-fslightbox="lightbox-hot-sales"
                                                    href=""
                                                >

                                                    <div
                                                        className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
                                                        style={{
                                                            background:
                                                                'url("' + (it.imagesPaths == null ? "https://cdn2.limonhost.net/wp-content/uploads/2020/10/404-not-found-sayfa-bulunamadi-hatasi-ve-cozumu.png" : ("https://3.72.47.28:3000/" + it.imagesPaths?.split(",")[0])) + '") center center no-repeat',
                                                            backgroundSize: 'cover'
                                                        }}
                                                    />
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-2x text-white" />
                                                    </div>
                                                </a>
                                                <div className="mt-5">
                                                    <a
                                                        href={`/admin/service/${it.serviceID}`}
                                                        className="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base"
                                                    >
                                                        {
                                                            it.description
                                                        }
                                                    </a>
                                                    <div className="fw-bold fs-5 text-gray-600 text-dark mt-3">
                                                        {
                                                            it.slug
                                                        }
                                                        {
                                                            it.verify
                                                        }
                                                    </div>
                                                    <div className="fs-6 fw-bolder mt-5 d-flex flex-stack">
                                                        <NavLink to={"/admin/service/" + it.serviceID} href="#" className="btn btn-sm btn-primary mx-auto">
                                                            Detay
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                })
                            }



                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

/**
 * 
 * @param {Object} props 
 * @param {Object} props.data 
 * @returns 
 */
function ApexChart1(props) {
    var options = {
        colors: ['#009ef7', '#50cd89', '#f1416c'],
        chart: {
            id: "basic-bar"
        },

        legend: {
            show: false,
            showForSingleSeries: false,
            showForNullSeries: false,
            showForZeroSeries: false,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: undefined,
                useSeriesColors: false
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        },

        dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            formatter: function (val, opts) {
                return val
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },
        toolbar: {
            show: false,

        },
        tooltip: {
            enabled: false,
            show: false,
        }
    }
    var series = [44, 66, 55];
    return <>
        {
            props.data == null ? "" : <Chart
                options={options}
                series={props.data}
                height={"100%"}
                type="donut"
            />
        }

    </>
}
export default Overview