import { useEffect } from "react"
import { Route, Routes, useNavigate, Navigate } from "react-router-dom"
import Varmi from "../../Helper/Varmi"
import Login from "./Login"


/**
 * Assign the project to an employee.
 * @param {Object} props - The employee who is responsible for the project.
 * @param {String} props.type - The employee who is responsible for the project.
 */
export default function Authorization(props) {



    var history = useNavigate()
    useEffect(() => {

        if (Varmi.isLogin) {
            history("/admin")
        }
        else {

        }

    }, [])
    function Router() {
        return <Routes>
            <Route path="/login" element={<Login />} />
            {
                Varmi.isLogin ? "" : <Route
                    path="*"
                    element={<Navigate to="/login" />}
                />
            }


        </Routes>
    }
    return <>
        <div id="kt_body" className="bg-body" style={{ height: "100vh" }}>
            <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                {/*begin::Authentication - Sign-in */}
                <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ backgroundImage: 'url(https://preview.keenthemes.com/metronic8/demo3/assets/media/illustrations/sigma-1/14.png' }}>
                    {/*begin::Content*/}
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                        <Router />
                    </div>
                    {/*end::Content*/}
                    {/*begin::Footer*/}
                    <div className="d-flex flex-center flex-column-auto p-10">
                        {/*begin::Links*/}
                        <div className="d-flex align-items-center fw-bold fs-6">
                            <a href="https://keenthemes.com" className="text-muted text-hover-primary px-2">About</a>
                            <a href="mailto:support@keenthemes.com" className="text-muted text-hover-primary px-2">Contact</a>
                            <a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2">Contact Us</a>
                        </div>
                        {/*end::Links*/}
                    </div>
                    {/*end::Footer*/}
                </div>
                {/*end::Authentication - Sign-in*/}
            </div>
        </div>
    </>
}