

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Footer from "./Footer";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
/**
 * Adds two numbers.
 * @param {Object} props The first number to add.
 * @param {Array} props.navigationMenu The second number to add.
 * @param {Object} props.userData The second number to add.
 * 
 */
export default function Body(props) {
    return <>
        <div>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LeftMenu navigationMenu={props.navigationMenu} userData={props.userData} />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <div className="container">
                            {/* <Header /> */}
                            {/* <div className="content d-flex flex-column flex-column-fluid" id="kt_content"> */}
                            {/* <div className="container-xxl" id="kt_content_container"> */}

                            {
                                props.children
                            }
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    </>


}
