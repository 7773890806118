import DBAuthorization from "./DBAuthorization/DBAuthorization";
import DBService from "./DBServices/dbServices";
import DBHome from "./DBHome/dbHome";
import DBUser from "./DBUsers/dbUsers";
import DBCategory from "./DBCategory/DBCategory";
import DBReport from "./DBReports/dbReports";
import DBParamaters from "./DBParamaters/DBParamaters";
import DBConversations from "./DBConversations/DBConversaions";

export default function DBManager() {
  return {
    Reports: DBReport(),
    Paramaters: DBParamaters(),
    Home: DBHome(),
    Services: DBService(),
    Authorization: DBAuthorization(),
    User: DBUser(),
    Conversations: DBConversations(),
    Category: DBCategory(),

  }

}