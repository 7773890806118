var AuthManager = (function () {

    // Instance stores a reference to the Singleton
    var instance;

    var elements = []
  
    function init() {
        return {
  
            
        AddElement: function (id,tittle) {
            elements.push({id:id,tittle:tittle});
        },
      };
  
    };
  
    return {
  
      // Get the Singleton instance if one exists
      // or create one if it doesn't
      getInstance: function () {
  
        if ( !instance ) {
          instance = init();
        }
  
        return instance;
      }
  
    };
  
  })();

  export default AuthManager