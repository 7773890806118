import { useRef, useState, useContext, useEffect } from "react";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import ContentLoader from 'react-content-loader'


import DataCenter from "../../../Database/DataCenter";
import EmptyArea from "../../../Helper/EmptyArea";
import Pagination from "react-js-pagination";


export default function ServiceList() {
    const navigate = useNavigate();
    const [contentLoading, setContentLoading] = useState(false);
    const [services, setServices] = useState([])
    const [category, setCategory] = useState([])
    const [selectedDay, setSelectedDay] = useState(null);

    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [page, setPage] = useState(0);
    const [stateLimit, setLimit] = useState(5);

    const [stateBeginDate, setBeginDate] = useState();
    const [stateEndDate, setEndDate] = useState();

    const [stateName, setStateName] = useState();
    const [stateCategory, setStateCategory] = useState();
    const [stateStatus, setStateStatus] = useState();



    useEffect(() => {
        DataCenter().Manager.Category.List().then(r => {
            setCategory(r.data)
        })
    }, [])
    useEffect(() => {
        setContentLoading(true);
        var name = searchParams.get("name");
        var status = searchParams.get("status");
        var beginDate = searchParams.get("beginDate");
        var endDate = searchParams.get("endDate");
        var page = searchParams.get("page");
        var limit = searchParams.get("limit");
        var category = searchParams.get("category");

        if (limit == null || page == null) {

            GenerateUrl()
            return;

        }
        setLimit(limit)
        setStateName(name)
        setPage(page)
        setStateStatus(status)

        setStateCategory(category)
        GetData(page, limit, name, beginDate, endDate, status, category)

    }, [searchParams])

    function ChangePagination(e) {
        // setPage((parseInt(e) - 1))
        navigate("/admin/service?status=all&page=" + (e - 1))
    }

    function GetData(page, limit, name, beginDate, endDate, status, category) {
        DataCenter().Manager.Services.List(page, limit, name, status, category, beginDate, endDate).then(r => (
            r !== "" ? setContentLoading(false) : "",
            setServices(r)
        ))
    }


    function GenerateUrl() {
        var name = searchParams.get("name");
        var status = searchParams.get("status");
        var beginDate = searchParams.get("beginDate");
        var endDate = searchParams.get("endDate");
        var page = searchParams.get("page");
        var limit = searchParams.get("limit");
        var category = searchParams.get("category");

        var myArray = {
            name: stateName == name ? name : stateName,
            status: stateStatus == status ? status : stateStatus,
            beginDate: stateBeginDate == beginDate ? beginDate : stateBeginDate,
            endDate: stateEndDate == endDate ? endDate : stateEndDate,
            page: page,
            limit: stateLimit == limit ? limit : stateLimit,
            category: stateCategory == category ? category : stateCategory,
        };
        var myURL = "";
        Object.keys(myArray).map(r => {

            if (r == "limit") {
                myURL += r + "=" + (myArray[r] ?? "5") + "&";
            } else if (r == "page") {
                myURL += r + "=" + (myArray[r] ?? "0") + "&";
            } else if (r == "status") {
                myURL += r + "=" + (myArray[r] ?? "all") + "&";
            }
            else {
                if (myArray[r] != null) {
                    myURL += r + "=" + (myArray[r]) + "&";
                }

            }
        })

        history('/admin/service?' + myURL)



    }

    function ChangeCategory(e) {

        setStateCategory(e.target.value)
    }

    function Find() {
        GenerateUrl()
    }




    return <div>
        <div className="card mb-7">
            <div className="card-body">
                <div id="kt_advanced_search_form" style={{}}>
                    <div className="row g-8 mb-8">
                        <div className="col-xxl-7">
                            <label className="fs-6 form-label fw-bolder text-dark">Search</label>
                            <div className="d-flex align-items-center">
                                <div className="position-relative w-100">
                                    <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x="17.0365"
                                                y="15.1223"
                                                width="8.15546"
                                                height={2}
                                                rx={1}
                                                transform="rotate(45 17.0365 15.1223)"
                                                fill="black"
                                            />
                                            <path
                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control form-control-solid ps-10"
                                        name="search"
                                        value={stateName}
                                        onChange={(e) => { setStateName(e.target.value) }}
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                            {/* <tags
                                className="tagify form-control form-control-solid"
                                tabIndex={-1}
                            >
                                <tag
                                    title="products"
                                    contentEditable="false"
                                    spellCheck="false"
                                    tabIndex={-1}
                                    className="tagify__tag tagify--noAnim"
                                    value="products"
                                >
                                    <x
                                        title=""
                                        className="tagify__tag__removeBtn"
                                        role="button"
                                        aria-label="remove tag"
                                    />
                                    <div>
                                        <span className="tagify__tag-text">products</span>
                                    </div>
                                </tag>
                                <tag
                                    title="users"
                                    contentEditable="false"
                                    spellCheck="false"
                                    tabIndex={-1}
                                    className="tagify__tag tagify--noAnim"
                                    value="users"
                                >
                                    <x
                                        title=""
                                        className="tagify__tag__removeBtn"
                                        role="button"
                                        aria-label="remove tag"
                                    />
                                    <div>
                                        <span className="tagify__tag-text">users</span>
                                    </div>
                                </tag>
                                <tag
                                    title="events"
                                    contentEditable="false"
                                    spellCheck="false"
                                    tabIndex={-1}
                                    className="tagify__tag tagify--noAnim"
                                    value="events"
                                >
                                    <x
                                        title=""
                                        className="tagify__tag__removeBtn"
                                        role="button"
                                        aria-label="remove tag"
                                    />
                                    <div>
                                        <span className="tagify__tag-text">events</span>
                                    </div>
                                </tag>
                                <span
                                    contentEditable=""
                                    tabIndex={0}
                                    data-placeholder="​"
                                    aria-placeholder=""
                                    className="tagify__input"
                                    role="textbox"
                                    aria-autocomplete="both"
                                    aria-multiline="false"
                                />

                            </tags>
                            <input
                                type="text"
                                className="form-control form-control form-control-solid"
                                name="tags"
                                defaultValue="products, users, events"
                            /> */}
                        </div>
                        <div className="col-xxl-5">
                            <div className="row g-8">

                                <div className="col-lg-12">
                                    <label className="fs-6 form-label fw-bolder text-dark">
                                        Onay Durumu
                                    </label>
                                    <div className="nav-group nav-group-fluid">
                                        <label>
                                            <input
                                                type="radio"
                                                className="btn-check"
                                                name="type"
                                                defaultValue="all"
                                                checked={stateStatus == "all"}
                                                onChange={(e) => { setStateStatus(e.target.value) }}
                                            />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                                                Hepsi
                                            </span>
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                className="btn-check"
                                                name="type"
                                                defaultValue="Approved"
                                                checked={stateStatus == "Approved"}
                                                onChange={(e) => { setStateStatus(e.target.value) }}
                                            />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                                                Onaylı
                                            </span>
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                className="btn-check"
                                                name="type"
                                                defaultValue="Rejected"
                                                checked={stateStatus == "Rejected"}
                                                onChange={(e) => { setStateStatus(e.target.value) }}
                                            />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                                                Onaysız
                                            </span>
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                className="btn-check"
                                                name="type"
                                                defaultValue="Pending"
                                                checked={stateStatus == "Pending"}
                                                onChange={(e) => { setStateStatus(e.target.value) }}
                                            />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                                                Beklemede
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-8">
                        <div className="col-lg-3">
                            <label className="fs-6 form-label fw-bolder text-dark">
                                Tarih Aralığı
                            </label>
                            <Example onDone={(from, to) => {
                                setBeginDate(from);
                                setEndDate(to)
                            }} />
                        </div>
                        <div className="col-lg-4">



                            <label className="fs-6 form-label fw-bolder text-dark">
                                Kategori
                            </label>
                            <select
                                className="form-select form-select-solid "
                                data-control="select2"
                                data-placeholder="In Progress"
                                data-hide-search="true"
                                onChange={(e) => { ChangeCategory(e) }}
                                data-select2-id="select2-data-10-6uic"
                                tabIndex={-1}
                                aria-hidden="true"
                            >
                                {
                                    category.map(it => {
                                        return <option value={it.id} selected={parseInt(it.id) == parseInt(stateCategory)}>{it.title}</option>
                                    })
                                }



                            </select>


                        </div>
                        <div className="col-lg-5 " style={{ position: 'relative', bottom: '-25px', margin: 'auto' }}>
                            <div className="btn btn-primary w-100" onClick={() => { Find() }}>Ara</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="card card-flush overflow-hidden w-initial">
            <div className="card-header pt-5">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-dark">Hizmet Listesi</span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">Sistemde kayıtlı toplam <b>{services.length}</b> hizmet bulunmaktadır.</span>
                </h3>
                {/*end::Title*/}
                {/* <div className="card-toolbar">
                    <NavLink to="/admin/service/insert" className="btn btn-primary font-weight-bolder">
                        <span className="svg-icon svg-icon-md">

                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <circle fill="#000000" cx={9} cy={15} r={6} />
                                    <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>Hizmet oluştur</NavLink>
                </div> */}


            </div>
            <div className="card-body py-4">
                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="table-responsive" style={{ minHeight: "500px" }}>
                        <EmptyArea dataList={services?.data}>
                            {contentLoading ? <ContentLoader
                                width={1500}
                                height={400}
                                viewBox="0 0 1500 400"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
                                <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
                                <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
                                <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
                                <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
                                <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
                                <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
                                <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
                                <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
                                <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
                                <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
                                <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
                                <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
                                <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
                                <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
                                <circle cx="1456" cy="203" r="12" />
                                <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
                                <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
                                <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
                                <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
                                <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
                                <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
                                <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
                                <circle cx="1456" cy="265" r="12" />
                                <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
                                <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
                                <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
                                <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
                                <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
                                <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
                                <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
                                <circle cx="1456" cy="323" r="12" />
                                <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
                                <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
                                <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
                                <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
                                <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
                                <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
                                <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
                                <circle cx="1456" cy="386" r="12" />
                                <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
                                <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
                                <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
                                <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
                                <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
                                <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
                                <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
                                <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
                                <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
                                <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
                                <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
                                <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
                                <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
                                <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
                                <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
                                <circle cx="37" cy="97" r="11" />
                                <rect x="26" y="23" rx="5" ry="5" width="153" height="30" />
                                <circle cx="1316" cy="88" r="11" />
                                <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
                                <circle cx="77" cy="96" r="11" />
                            </ContentLoader> :
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Hizmet ID</th>
                                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Fotoğraf</th>
                                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Hizmet Veren</th>
                                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Category</th>
                                            {/* <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1}>Mesaj Sayısı</th> */}
                                            {/* <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Durum</th> */}
                                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Oluşturulma Tarihi</th>
                                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Doğrulama Durumu</th>

                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 fw-bold">
                                        {services?.data?.length ? services?.data.map((service, index) => (
                                            <tr className="odd" key={index}>
                                                <td>
                                                    <NavLink to={"/admin/service/" + service.serviceID}>
                                                        {service.serviceID}
                                                    </NavLink>

                                                </td>
                                                <td className="d-flex align-items-center">
                                                    <div className="symbol symbol-circle symbol-50px me-3" >
                                                        <a href="">
                                                            <div className="symbol-label ">
                                                                <img src={(service?.creatorImage == null ? "https://lh3.googleusercontent.com/xTUtaQ-Og5TDsw0j1CNYZx9qMx3RxOGFkBXNvQjeYli-KepZY945RXMTz0Bra9MfBg" : (process.env.REACT_APP_IMAGE + "/" + service?.creatorImage))} alt="Emma Smith" className="w-100" />
                                                                <div className={(service?.status == 'Offline' ? 'offline-circle' : service?.status == 'Online' ? 'online-circle' : '')} />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column">
                                                        <div>
                                                            <div className="fs-4 fw-bolder"><NavLink to={"/admin/user/" + service.creatorID}>  {service.creatorName}</NavLink> </div>
                                                            <div className="fs-6 fw-bold text-gray-400"> {service.creatorID}</div>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className="fs-5 fw-bolder">{(service.categoryName == null ? 'Belirtilmemiş' : service.categoryName)}</div>
                                                        <div className="fs-6 fw-bold text-gray-400">{(service.description === '' || null ? 'Belirtilmemiş' : service.description)}</div>
                                                    </div>
                                                </td>
                                                {/* <td>{service.price === null ? 0 : service.price}</td> */}
                                                {/* <td >
                                        <div className={"badge py-3 px-4 fs-7 badge-light-" + (service.status === "active" ? 'success' : 'danger')}>{service.status}</div>
                                    </td> */}
                                                <td >
                                                    <div >{service.createDate}</div>
                                                </td>
                                                <td >
                                                    <div className={"badge py-3 px-4 fs-7 badge-light-" + (service.verify === "Approved" ? 'success' : service.verify === 'Pending' ? 'warning' : 'danger')}>{service.verify}</div>
                                                </td>


                                            </tr>
                                        )) : ""}
                                    </tbody>
                                </table>
                            }
                            <Pagination
                                activePage={(parseInt(page) + 1)}
                                itemsCountPerPage={(stateLimit)}
                                totalItemsCount={services?.totalItemCount}
                                pageRangeDisplayed={5}
                                itemClass={"paginate_button page-item"}
                                innerClass={"pagination"}
                                linkClass={"page-link"}
                                activeClass={" active"}
                                itemClassPrev={"previous"}
                                prevPageText={<i className="previous"></i>}
                                nextPageText={<i className="next"></i>}
                                activeLinkClass={""}
                                onChange={(e) => { ChangePagination(e) }}
                            />
                        </EmptyArea>

                    </div>
                </div>
                <div className="card-footer">

                </div>
            </div>
        </div>
    </div >
}


const Example = (props) => {
    const defaultFrom = {
        year: 2019,
        month: 1,
        day: 1,
    };
    const defaultTo = {
        year: 2022,
        month: 12,
        day: 1,
    };
    const defaultValue = {
        from: defaultFrom,
        to: defaultTo,
    };
    const [selectedDayRange, setSelectedDayRange] = useState(
        defaultValue
    );

    useEffect(() => {
        var from;
        var to;
        if (selectedDayRange.to == null || selectedDayRange.from == null) {

        }
        else {
            from = selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
            to = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
            props.onDone(from, to)
        }

    }, [selectedDayRange])
    const formatInputValue = () => {
        console.log(selectedDayRange.to)
        if (selectedDayRange.to == null || selectedDayRange.from == null) return '';
        return selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day + ">" + selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
    };
    return (
        <DatePicker
            value={selectedDayRange}
            onChange={setSelectedDayRange}
            inputPlaceholder="Tarih Aralığı Seç.."
            formatInputText={formatInputValue}
            calendarPopperPosition="bottom"
            wrapperClassName="form-control form-control form-control-solid"
            inputClassName="form-control form-control form-control-solid p-0"
            shouldHighlightWeekends="true"
        />
    );
};