import { useEffect, useState } from "react"
import DBManager from "../../../Database/Manager/DBManager";
import DataCenter from '../../../Database/DataCenter'
import LoadingButton from "../../../Template/Helper/LoadingButton";
import Alert from "../../../Template/Helper/Alert";

export default function Paramaters() {
    const [loading, setLoading] = useState(false);
    const [paramaterList, setParamaterList] = useState([])
    const [updateRes, setUpdateRes] = useState();

    useEffect(() => {
        let mounted = true;
        DataCenter().Manager.Paramaters.List().then(r => {
            mounted && setParamaterList(r);
        })
        return () => {
            mounted = false;
        }
    }, [])

    function UpdateParamaters() {
        setLoading(true);
        paramaterList?.length && paramaterList.map(r => {
            DataCenter().Manager.Paramaters.Update(r.variable_key, r.variable_value).then(res => {
                if (res.status === 200) {
                    setLoading(false);
                }
            })
        })
        if (paramaterList.length > 0) {
            Alert().Success("İşlem başarılı bir şekilde gerçekleştirildi.");
        }
    }

    function ChangeParamaters(key, value) {
        var index = paramaterList.findIndex((e) => { return e.variable_key == key })

        paramaterList[index].variable_value = value;
        setParamaterList([...paramaterList])

    }

    return <>

        <div className="row">
            <div className="col-md-6">
                <div className="card card-flush pt-3 mb-5 mb-lg-10">
                    <div className="card-header">
                        <div className="card-title">
                            <h2 className="fw-bolder">Sıralama Algoritması</h2>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="d-flex flex-column mb-15 fv-row">


                            <div className="fs-5 fw-bolder form-label mb-3">Kat sayı değerleri
                                <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Add custom fields to the billing invoice." data-bs-original-title title /></div>
                            <div className="table-responsive">
                                <div id="kt_create_new_custom_fields_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table id="kt_create_new_custom_fields" className="table align-middle table-row-dashed fw-bold fs-6 gy-5 dataTable no-footer">
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1} style={{ width: '356px' }}>Field Name</th>
                                            <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1} style={{ width: '357.55px' }}>Field Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paramaterList?.length ? paramaterList.map((it, index) => {
                                                if (it.variable_key.split("_")[0] == "sort")
                                                    return <tr className="odd" key={index}>
                                                        <td>
                                                            <input type="text" className="form-control form-control-solid" disabled name="null-0" defaultValue={it.variable_key} />
                                                        </td>
                                                        <td>
                                                            <input onChange={(e) => { ChangeParamaters(it.variable_key, e.target.value) }} type="text" className="form-control form-control-solid" name="null-0" defaultValue={it.variable_value} />
                                                        </td>

                                                    </tr>
                                            }) : ""
                                        }

                                    </tbody>
                                </table></div><div className="row"><div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" /><div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" /></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card card-flush pt-3 mb-5 mb-lg-10">
                    <div className="card-header">
                        <div className="card-title">
                            <h2 className="fw-bolder">Uygulama Ayarları</h2>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="d-flex flex-column mb-15 fv-row">
                            <div className="fs-5 fw-bolder form-label mb-3">Değişkenler
                                <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Add custom fields to the billing invoice." data-bs-original-title title /></div>
                            <div className="table-responsive">
                                <div id="kt_create_new_custom_fields_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table id="kt_create_new_custom_fields" className="table align-middle table-row-dashed fw-bold fs-6 gy-5 dataTable no-footer">
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1} style={{ width: '356px' }}>Field Name</th>
                                            <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1} style={{ width: '357.55px' }}>Field Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paramaterList?.length && paramaterList.map((it, i) => {
                                                if (it.variable_key.split("_")[0] != "sort")
                                                    return <tr className="odd" key={i}>
                                                        <td>
                                                            <input type="text" className="form-control form-control-solid" disabled name="null-0" defaultValue={it.variable_key} />
                                                        </td>
                                                        <td>
                                                            <input onChange={(e) => { ChangeParamaters(it.variable_key, e.target.value) }} type="text" className="form-control form-control-solid" name="null-0" defaultValue={it.variable_value} />
                                                        </td>

                                                    </tr>
                                            })
                                        }

                                    </tbody>
                                </table>
                                </div>
                                    <div className="row"><div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" /><div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" /></div></div>
                            </div>
                        </div>
                        <LoadingButton loading={loading} onClick={UpdateParamaters} buttonText="Parametreleri Güncelle" />
                    </div>
                </div>
            </div>
        </div>


    </>
}