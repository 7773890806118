import axiosInstance from "../../Helper/axiosInstance";




export default function DBHome() {
  var baseURL = process.env.REACT_APP_BASE_URL_V4
  function Statistics() {
    return new Promise((success, reject) => {
      axiosInstance.get(`${baseURL}/admin/home/statistics`)
        .then(function (response) {
          return success(response.data);
        })
        .catch(function (error) {
          reject(error);
        })
    });
  }

  function PendingList() {
    return new Promise((success, reject) => {
      axiosInstance.get(`${baseURL}/admin/home/pending-list`)
        .then(function (response) {
          success(response?.data);
        }).catch((err) => {
          reject(err)
        })
    })
  }

  function Statistics2() {
    return new Promise((success, reject) => {
      axiosInstance.get(`${baseURL}/admin/home/statistics2`)
        .then(function (response) {
          console.log(response);
          return success(response.data);
        })
        .catch(function (error) {
          reject(error);
        })
    });
  }


  return {
    Statistics, PendingList, Statistics2
  }
}