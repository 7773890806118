import React from 'react'

function PaymentList() {

    const payment = [
        {
            id: "1",
            service: "service",
            date: "01-02-2022 12:05",
            description: "description",
            status: "Paid"

        },
        {
            id: "2",
            service: "service",
            date: "01-02-2022 12:05",
            description: "description",
            status: "Pending"
        },
        {
            id: "3",
            service: "service",
            date: "01-02-2022 12:05",
            description: "description",
            status: "Not Paid"
        },
        {
            id: "4",
            service: "service",
            date: "01-02-2022 12:05",
            description: "description",
            status: "Paid"
        },
        {
            id: "5",
            service: "service",
            date: "01-02-2022 12:05",
            description: "description",
            status: "Paid"
        },

    ]
    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <span className="svg-icon svg-icon-1 position-absolute ms-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height={2} rx={1} transform="rotate(45 17.0365 15.1223)" fill="black" />
                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                            </svg>
                        </span>
                        <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search user" />
                    </div>
                </div>
            </div>
            <div className="card-body py-4">
                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                    <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >ID</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Service</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Description</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Date</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Status</th>
                            <th className="text-center " rowSpan={1} colSpan={1} >Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-bold">
                        {payment.map((user, index) => (
                            <tr className="odd" key={index}>
                                <td>
                                    {user.id}
                                </td>
                                <td>
                                    {user.service}
                                </td>
                                <td>{user.description}</td>
                                <td >{user.date} </td>
                                <td >{user.status} </td>
                                <td className="text-center">
                                    <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                                        <span className="svg-icon svg-icon-5 m-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                                            </svg>
                                        </span>
                                    </a>
                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">
                                        <div className="menu-item px-3">
                                            <a href="" className="menu-link px-3">Edit</a>
                                        </div>
                                        <div className="menu-item px-3">
                                            <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                            <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                                <ul className="pagination">
                                    <li className="paginate_button page-item previous disabled" id="kt_table_users_previous">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx={0} tabIndex={0} className="page-link">
                                            <i className="previous" />
                                        </a>
                                    </li>
                                    <li className="paginate_button page-item active">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx={1} tabIndex={0} className="page-link">1</a>
                                    </li>
                                    <li className="paginate_button page-item ">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx={2} tabIndex={0} className="page-link">2</a>
                                    </li>
                                    <li className="paginate_button page-item ">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx={3} tabIndex={0} className="page-link">3</a>
                                    </li>
                                    <li className="paginate_button page-item next" id="kt_table_users_next">
                                        <a href="#" aria-controls="kt_table_users" data-dt-idx={4} tabIndex={0} className="page-link">
                                            <i className="next" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentList