import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



const MySwal = withReactContent(Swal)

export default function Alert() {
    function Success(tittle) {
        MySwal.fire({
            position: 'top-center',
            icon: 'success',
            title: tittle === undefined ? 'İşlem başarılı bir şekilde gerçekleştirildi' : tittle,
            showConfirmButton: false,
            timer: 1500
        })
    }
    function Fail(tittle) {
        MySwal.fire({
            position: 'top-center',
            icon: 'error',
            title: tittle === undefined ? 'İşlem başarılı bir şekilde gerçekleştirildi' : tittle,
            showConfirmButton: false,
            timer: 1500
        })
    }
    function Question(tittle, description, callback) {
        return new Promise((success, fail) => {
            MySwal.fire({
                position: 'top-center',
                icon: 'error',
                title: tittle === undefined ? 'Ops! Bir sorun oluştu lütfen sistem yetkilisi ile iletişime geçin' : tittle,
                text: description === undefined ? "" : description,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Evet',
                cancelButtonText: 'Hayır',

            }).then((result) => {
                success(result.isConfirmed);

            })
        })


    }
    return {
        Success, Fail, Question
    }
}