import { useEffect, useState } from "react"
import AuthManager from "./AuthManager";
/**
 * Form for user login
 * @param {object} props Component props
 * @param {number} props.viewID Shows if form submitting is in progress
 * @param {string} props.tittle Shows if form submitting is in progress
 */
export default function AuthControl (props)
{

    const [isShow,setShow] = useState(false);

    useEffect(()=>{

        var userPermission = "1,2,3,4,5,6,7,8";

        var permissionArray = userPermission.split(",");

       setShow( permissionArray.findIndex((it)=>{return it == props.viewID}) != -1)
  
        

    },[])
    return <>
    
        {
            isShow ? props.children : ""
        }
    </>
}