import { useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Denebi from "../../Helper/Denebi";
import varmiLogo from "../../Assets/logo/varmi-logo.png"
import DataCenter from "../../Database/DataCenter";
import Varmi from "../../Helper/Varmi";
import Swal from 'sweetalert2'

export default function Login() {


    var inputEmail = useRef(null);
    var inputPassword = useRef(null);

    var history = useNavigate();




    return <>
        <a href="/metronic8/demo3/../demo3/index.html" className="mb-12">
            <img alt="Logo" src={varmiLogo} className="h-100px" />
        </a>
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate" id="kt_sign_in_form" action="#">
                <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Giriş Yap</h1>
                    <div className="text-gray-400 fw-bold fs-4">Varmi Yönetim Paneline?
                        <NavLink to="/register" className="link-primary fw-bolder"> Hoş Geldiniz</NavLink>
                    </div>
                </div>
                <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="form-label fs-6 fw-bolder text-dark">Kullanıcı Adı</label>
                    <input ref={inputEmail} className="form-control form-control-lg form-control-solid" type="text" name="email" autoComplete="off" />
                    <div className="fv-plugins-message-container invalid-feedback" /></div>
                <div className="fv-row mb-10 fv-plugins-icon-container">
                    <div className="d-flex flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">Şifre</label>

                    </div>
                    <input ref={inputPassword} className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" />
                    <div className="fv-plugins-message-container invalid-feedback" /></div>
                <div className="text-center">
                    <button onClick={() => { ButtonLogin() }} type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                        <span className="indicator-label">Giriş Yap</span>
                    </button>
                </div>
                <div />
            </div>
        </div>
    </>


    function ButtonLogin() {
        DataCenter().Manager.Authorization.Login(inputEmail.current.value, inputPassword.current.value).then(r => {

            console.log(r);
            if (r.code != -1) {
                if (r.code)
                    Swal.fire(
                        'Good job!',
                        'You clicked the button!',
                        'success'
                    ).then((result) => {
                        console.log(result);
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            Varmi.setLogin(JSON.stringify(r.userData))
                            window.location.href = window.location.href
                        } else if (result.isDenied) {

                        }
                    })

            }
            else {
                Swal.fire(
                    'Ops!',
                    'Malesef kullanıcı adın ya da şifren yanlış!',
                    'error'
                ).then((result) => {

                })
            }



        }).catch(r => {


        })


    }
}