import React, { useContext, useEffect, useState } from 'react'
import Complain from '../Charts/Complain'
import Changers from '../Charts/Changers'
import Varben from '../Charts/Varben'
import Chart from "react-apexcharts";
import Daily from '../Charts/Daily'

import AuthControl from "../../../Helper/AuthControl";
import EmptyArea from '../../../Helper/EmptyArea';
import DataCenter from '../../../Database/DataCenter';
import { useSearchParams, useNavigate, NavLink, useParams } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import ContentLoader from 'react-content-loader';
import Varmi from '../../../Helper/Varmi';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';


function Dashborad() {

    //States for Pagination
    const [offset, setOffset] = useState(1);
    const [photos, setPhotos] = useState([]);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(70)

    const [users, setUsers] = useState();
    const [photoLimit, setPhotoLimit] = useState(5);
    const [allPage, setAllPage] = useState();
    const [pendingPhotos, setPendingPhotos] = useState([]);
    const [pendingUsers, setPendingUsers] = useState([]);
    const [activeIndex, setActiveIndex] = useState();
    const [contentLoading, setContentLoading] = useState(false);
    const [statistics, setStatistics] = useState([]);
    const [selectedStatistics, setSelectedStatistics] = useState();
    const [selectedIndex, setSelectedIndex] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [stateLimit, setStateLimit] = useState(1);
    const [page, setPage] = useState(0);
    const [changeImagesList, setChangeImagesList] = useState([]);
    const [data, setData] = useState({});
    const [tab, setTab] = useState();

    const [searchParams, setSearchParams] = useSearchParams();
    var navigate = useNavigate()

    const [fromUpdateData, setFromUpdateData] = useState();
    const [typePeriod, setTypePeriod] = useState();

    function ChangePagination(e) {
        // setPage((parseInt(e) - 1))
        navigate("/admin/home?fromService=" + (e - 1) + "&fromUpdatedData=" + fromUpdateData + "&type=" + typePeriod + "&tab=" + activeIndex)
    }

    function getData() {
        console.log(pendingPhotos);
        const slice1 = pendingPhotos?.slice((offset * perPage), (offset * perPage) + perPage);
        setPhotos([...slice1]);
        setPageCount(pendingPhotos?.length);

        const slice2 = pendingUsers?.slice((offset * perPage), (offset * perPage) + perPage);
        setUsers([...slice2]);
        setPageCount(pendingUsers?.length);
    }

    useEffect(() => {
        console.log(offset);
        getData();
    }, [offset]);

    const handlePageClick = (e) => {
        setOffset(e - 1)
        // navigate("/admin/home/2?fromService=" + (e - 1));
    };

    useEffect(() => {
        let mounted = true;
        setContentLoading(true);
        DataCenter().Manager.Home.PendingList().then(res => {
            if (res !== "" && mounted) {
                setContentLoading(false);
                setPendingPhotos(res?.pendingServiceImage);
                setPendingUsers(res?.pendingAuthUser);
            }
        })

        DataCenter().Manager.Services.List(page, stateLimit, null, "Pending").then(r => (
            r !== "" && mounted ? (setContentLoading(false), setServicesList(r)) : ""
        ))
        return () => {
            mounted = false;
        }
    }, []);

    useEffect(() => {
        let mounted = true;
        setContentLoading(true);
        var fromService = searchParams.get("fromService");
        var fromUpdatedData = searchParams.get("fromUpdatedData");
        var type = searchParams.get("type");
        var tab = searchParams.get("tab");
        var page = searchParams.get("page");

        console.log("fromService", fromService);
        console.log("fromUpdatedData", fromUpdatedData);
        console.log("type", type);
        console.log("tab", tab);
        console.log("page", page);

        setFromUpdateData(fromUpdatedData);
        setTypePeriod(type);
        setPage(fromService);
        setAllPage(page);
        setTab(tab);

        if (fromService == null || fromUpdatedData == null || type == null || tab === null) {
            if (page === null || page === "" || page === undefined) {
                navigate("/admin/home?fromService=" + (fromService ?? 0) + "&fromUpdatedData=" + (fromUpdatedData ?? 0) + "&type=" + (type ?? "week") + "&tab=" + (tab ?? 1))
            }
            else {
                // navigate("/admin/home/" + page);
            }
            return ""
        }

        if (type == "month") {
            setSelectedIndex(0)
        }
        else {
            setSelectedIndex(1)
        }

        if (tab == 1) {
            setActiveIndex(1);
        }
        else if (tab == 2) {
            setActiveIndex(2);
        } else {
            setActiveIndex(3);
        }
        DataCenter().Manager.Services.List(fromService, stateLimit, null, "Pending").then(r => (
            r !== "" && mounted ? (setContentLoading(false), setServicesList(r)) : ""
        ))
        DataCenter().Manager.Home.Statistics().then(r => {
            setStatistics(r)
            if (type == "week") {
                setSelectedStatistics(r.Last7DayData)
            }
            else {
                setSelectedStatistics(r.Last30DayData)
            }

        })
        DataCenter().Manager.Home.Statistics2().then(r => {
            setData(r[0])

        })
        return () => {
            mounted = false;
        }
    }, [searchParams])

    function ChangeTypeStatistics(type) {
        var fromService = searchParams.get("fromService");
        var fromUpdatedData = searchParams.get("fromUpdatedData");
        navigate("/admin/home?fromService=" + (fromService ?? 1) + "&fromUpdatedData=" + (fromUpdatedData ?? 1) + "&type=" + (type == 1 ? "week" : "month") + "&tab=" + (activeIndex ?? 1))

    }

    function ChangeIndex(index) {
        var fromService = searchParams.get("fromService");
        var fromUpdatedData = searchParams.get("fromUpdatedData");
        // var tab = searchParams.get("tab");
        navigate("/admin/home?from=" + fromService + "&fromUpdatedData=" + fromUpdatedData + "&type=" + typePeriod + "&tab=" + index)
    }

    function ChangePage(pageNo) {
        navigate("/admin/home/" + pageNo)
    }

    return <>
        {
            allPage === null || allPage === "" || allPage === undefined ?
                <>
                    <div id="kt_header" className="header">
                        <div className="container d-flex flex-stack flex-wrap gap-2" id="kt_header_container">
                            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                <h1 className="d-flex flex-column text-dark fw-bolder my-0 fs-1">Kontrol Paneli</h1>
                                <ul className="breadcrumb breadcrumb-dot fw-bold fs-base my-1">
                                    <li className="breadcrumb-item text-muted">
                                        <a href="/metronic8/demo3/../demo3/index.html" className="text-muted">Ana sayfa</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex d-lg-none align-items-center ms-n2 me-2">
                                <div className="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle" onClick={() => { alert("asfasf"); Varmi.openProfile() }}>
                                    <span className="svg-icon svg-icon-1 mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                            <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                                        </svg>
                                    </span>
                                </div>

                            </div>
                            <div className="d-flex align-items-center flex-shrink-0">
                                <ul className="nav" id="kt_chart_widget_8_tabs">
                                    <li className="nav-item">
                                        <div onClick={() => { ChangeTypeStatistics(0) }} className={"nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4 me-1" + (selectedIndex == 0 ? " active" : "")}>Aylık Özet</div>
                                    </li>
                                    <li className="nav-item">
                                        <div onClick={() => { ChangeTypeStatistics(1) }} className={"nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4 me-1" + (selectedIndex == 1 ? " active" : "")}>Haftalık Özet</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div id="kt_content_container">
                        <div>
                            <div className="row gy-5 g-xl-10">
                                <div className="col-sm-6 col-xl-3 mb-xl-10">
                                    <div className="card h-lg-100">
                                        <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                            <div className="m-0">
                                                <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <path opacity="0.3" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="black" />
                                                        <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="black" />
                                                    </svg>
                                                </span>
                                            </div>


                                            <div className="d-flex flex-column my-7">
                                                <span className="fw-bold fs-3x text-gray-800 lh-1 ls-n2">{data?.serviceCount}</span>
                                                <div className="m-0">
                                                    <span className="fw-bold fs-6 text-gray-400">Hizmetler</span>
                                                </div>
                                            </div>
                                            <span className="badge badge-success fs-base">
                                                <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x={13} y={6} width={13} height={2} rx={1} transform="rotate(90 13 6)" fill="black" />
                                                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
                                                    </svg>
                                                </span>2.1%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-3 mb-xl-10">
                                    <div className="card h-lg-100">
                                        <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                            <div className="m-0">
                                                <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <path opacity="0.3" d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z" fill="black" />
                                                        <path d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="black" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column my-7">
                                                <span className="fw-bold fs-3x text-gray-800 lh-1 ls-n2">{data?.userCount}</span>
                                                <div className="m-0">
                                                    <span className="fw-bold fs-6 text-gray-400">Kullanıcı</span>
                                                </div>
                                            </div>
                                            <span className="badge badge-success fs-base">
                                                <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x={13} y={6} width={13} height={2} rx={1} transform="rotate(90 13 6)" fill="black" />
                                                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
                                                    </svg>
                                                </span>2.1%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-3 mb-xl-10">
                                    <div className="card h-lg-100">
                                        <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                            <div className="m-0">
                                                <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="black" />
                                                        <path d="M8.70001 6C8.10001 5.7 7.39999 5.40001 6.79999 5.10001C7.79999 4.00001 8.90001 3 10.1 2.2C10.7 2.1 11.4 2 12 2C12.7 2 13.3 2.1 13.9 2.2C12 3.2 10.2 4.5 8.70001 6ZM12 8.39999C13.9 6.59999 16.2 5.30001 18.7 4.60001C18.1 4.00001 17.4 3.6 16.7 3.2C14.4 4.1 12.2 5.40001 10.5 7.10001C11 7.50001 11.5 7.89999 12 8.39999ZM7 20C7 20.2 7 20.4 7 20.6C6.2 20.1 5.49999 19.6 4.89999 19C4.59999 18 4.00001 17.2 3.20001 16.6C2.80001 15.8 2.49999 15 2.29999 14.1C4.99999 14.7 7 17.1 7 20ZM10.6 9.89999C8.70001 8.09999 6.39999 6.9 3.79999 6.3C3.39999 6.9 2.99999 7.5 2.79999 8.2C5.39999 8.6 7.7 9.80001 9.5 11.6C9.8 10.9 10.2 10.4 10.6 9.89999ZM2.20001 10.1C2.10001 10.7 2 11.4 2 12C2 12 2 12 2 12.1C4.3 12.4 6.40001 13.7 7.60001 15.6C7.80001 14.8 8.09999 14.1 8.39999 13.4C6.89999 11.6 4.70001 10.4 2.20001 10.1ZM11 20C11 14 15.4 9.00001 21.2 8.10001C20.9 7.40001 20.6 6.8 20.2 6.2C13.8 7.5 9 13.1 9 19.9C9 20.4 9.00001 21 9.10001 21.5C9.80001 21.7 10.5 21.8 11.2 21.9C11.1 21.3 11 20.7 11 20ZM19.1 19C19.4 18 20 17.2 20.8 16.6C21.2 15.8 21.5 15 21.7 14.1C19 14.7 16.9 17.1 16.9 20C16.9 20.2 16.9 20.4 16.9 20.6C17.8 20.2 18.5 19.6 19.1 19ZM15 20C15 15.9 18.1 12.6 22 12.1C22 12.1 22 12.1 22 12C22 11.3 21.9 10.7 21.8 10.1C16.8 10.7 13 14.9 13 20C13 20.7 13.1 21.3 13.2 21.9C13.9 21.8 14.5 21.7 15.2 21.5C15.1 21 15 20.5 15 20Z" fill="black" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column my-7">
                                                <span className="fw-bold fs-3x text-gray-800 lh-1 ls-n2">{data?.messaging}</span>
                                                <div className="m-0">
                                                    <span className="fw-bold fs-6 text-gray-400">Mesajlar</span>
                                                </div>
                                            </div>
                                            <span className="badge badge-danger fs-base">
                                                <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x={11} y={18} width={13} height={2} rx={1} transform="rotate(-90 11 18)" fill="black" />
                                                        <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="black" />
                                                    </svg>
                                                </span>0.47%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-3 mb-xl-10">
                                    <div className="card h-lg-100">
                                        <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                            <div className="m-0">
                                                <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <path d="M8.7 4.19995L4 6.30005V18.8999L8.7 16.8V19L3.1 21.5C2.6 21.7 2 21.4 2 20.8V6C2 5.4 2.3 4.89995 2.9 4.69995L8.7 2.09998V4.19995Z" fill="black" />
                                                        <path d="M15.3 19.8L20 17.6999V5.09992L15.3 7.19989V4.99994L20.9 2.49994C21.4 2.29994 22 2.59989 22 3.19989V17.9999C22 18.5999 21.7 19.1 21.1 19.3L15.3 21.8998V19.8Z" fill="black" />
                                                        <path opacity="0.3" d="M15.3 7.19995L20 5.09998V17.7L15.3 19.8V7.19995Z" fill="black" />
                                                        <path opacity="0.3" d="M8.70001 4.19995V2L15.4 5V7.19995L8.70001 4.19995ZM8.70001 16.8V19L15.4 22V19.8L8.70001 16.8Z" fill="black" />
                                                        <path opacity="0.3" d="M8.7 16.8L4 18.8999V6.30005L8.7 4.19995V16.8Z" fill="black" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column my-7">
                                                <span className="fw-bold fs-3x text-gray-800 lh-1 ls-n2">{data?.varbencount}</span>
                                                <div className="m-0">
                                                    <span className="fw-bold fs-6 text-gray-400">Varben</span>
                                                </div>
                                            </div>
                                            <span className="badge badge-success fs-base">
                                                <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x={13} y={6} width={13} height={2} rx={1} transform="rotate(90 13 6)" fill="black" />
                                                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
                                                    </svg>
                                                </span>2.1%</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-3 mb-10 mt-4 mb-xl-6">
                                <div className="card card-flush h-md-250px mb-10">
                                    <div className="card-header pt-5 ">
                                        <div className='card-title align-items-start flex-column'>
                                            <div className='w-100'>
                                                <div className="d-flex align-items-center">
                                                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{selectedStatistics?.service?.totalCount}</span>
                                                    <span className={`badge ${selectedStatistics?.service?.last30DayOldPercent < 0 ? "badge-danger" : "badge-success"} fs-6 lh-1 py-1 d-flex flex-center`} style={{ height: '22px' }}>
                                                        <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                                            {
                                                                selectedStatistics?.service?.last30DayOldPercent < 0 ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.5" d="M13 14.4V3.00003C13 2.40003 12.6 2.00003 12 2.00003C11.4 2.00003 11 2.40003 11 3.00003V14.4H13Z" fill="black" />
                                                                        <path d="M5.7071 16.1071C5.07714 15.4771 5.52331 14.4 6.41421 14.4H17.5858C18.4767 14.4 18.9229 15.4771 18.2929 16.1071L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L5.7071 16.1071Z" fill="black" />
                                                                    </svg>
                                                                    :
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                                                        <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                                                    </svg>
                                                            }
                                                        </span>
                                                        {selectedStatistics?.service?.last30DayOldPercent.round()}%
                                                    </span>
                                                </div>
                                            </div>
                                            <span className="text-gray-400 pt-1 fw-bold fs-6">Hizmet İlanı Sayısı</span>
                                        </div>
                                    </div>
                                    <div className="card-body d-flex align-items-end pt-0 py-0">
                                        <Changers data={selectedStatistics?.service?.array} />
                                    </div>
                                </div>
                                <div className="card card-flush h-md-250px ">

                                    <div className="card-header pt-5">

                                        <div className="d-flex align-items-center w-100">
                                            <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{selectedStatistics?.messageConversion?.totalCount}</span>
                                            <span className={`badge ${statistics?.last30Day?.message?.last30DayOldPercent < 0 ? "badge-danger" : "badge-success"} fs-6 lh-1 py-1 d-flex flex-center`} style={{ height: '22px' }}>
                                                <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                                    {
                                                        statistics?.last30Day?.message?.last30DayOldPercent < 0 ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.5" d="M13 14.4V3.00003C13 2.40003 12.6 2.00003 12 2.00003C11.4 2.00003 11 2.40003 11 3.00003V14.4H13Z" fill="black" />
                                                                <path d="M5.7071 16.1071C5.07714 15.4771 5.52331 14.4 6.41421 14.4H17.5858C18.4767 14.4 18.9229 15.4771 18.2929 16.1071L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L5.7071 16.1071Z" fill="black" />
                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                                                <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                                            </svg>
                                                    }
                                                </span>
                                                {selectedStatistics?.messageConversion?.last30DayOldPercent.round()}%
                                            </span>
                                        </div>
                                        <span className="text-gray-400 pt-1 fw-bold fs-6">Sohbet başlatma</span>
                                    </div>
                                    <div className="pt-2 d-flex align-items-end justify-content-between card-body" style={{ height: "100%" }}>
                                        <div className=" pt-2 pe-2">
                                            <div
                                                id="kt_card_widget_4_chart"
                                                style={{ minWidth: 70, width: "initial" }}
                                                data-kt-size={70}
                                                data-kt-line={11}
                                            >
                                                <span />
                                                <ApexChart1 data={[selectedStatistics?.messageConversion?.totalCount, selectedStatistics?.messageConversion?.successConversation]} />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <div className="d-flex fs-6 fw-bold align-items-center">
                                                <div className="bullet w-8px h-6px rounded-2 bg-danger me-1" />
                                                <div className="text-gray-500 flex-grow-1 ">Sohbet</div>
                                                <div className="fw-boldest text-gray-700 text-xxl-end">{selectedStatistics?.messageConversion?.totalCount}</div>
                                            </div>
                                            <div className="d-flex fs-6 fw-bold align-items-center my-3">
                                                <div className="bullet w-8px h-6px rounded-2 bg-primary me-1" />
                                                <div className="text-gray-500 flex-grow-1 ">Ödeme</div>
                                                <div className="fw-boldest text-gray-700 text-xxl-end">{selectedStatistics?.messageConversion?.successConversation}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-3 mb-10 mt-4 mb-xl-6">
                                <div className="card card-flush h-md-250px mb-10">
                                    <div className="card-header pt-5">
                                        <div className="d-flex align-items-center w-100">
                                            <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{selectedStatistics?.user?.totalCount}</span>
                                            <span className={`badge ${selectedStatistics?.user?.last30DayOldPercent < 0 ? "badge-danger" : "badge-success"} fs-6 lh-1 py-1 d-flex flex-center`} style={{ height: '22px' }}>
                                                <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                                    {

                                                        selectedStatistics?.user?.last30DayOldPercent < 0 ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.5" d="M13 14.4V3.00003C13 2.40003 12.6 2.00003 12 2.00003C11.4 2.00003 11 2.40003 11 3.00003V14.4H13Z" fill="black" />
                                                                <path d="M5.7071 16.1071C5.07714 15.4771 5.52331 14.4 6.41421 14.4H17.5858C18.4767 14.4 18.9229 15.4771 18.2929 16.1071L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L5.7071 16.1071Z" fill="black" />
                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                                                <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                                            </svg>
                                                    }
                                                </span>

                                                {selectedStatistics?.user?.last30DayOldPercent?.round()}%
                                            </span>
                                        </div>
                                        <span className="text-gray-400 pt-1 fw-bold fs-6">Kullanıcı Sayısı</span>
                                    </div>
                                    <div className="d-flex align-items-end pt-0 card-body py-0 ">
                                        <Complain data={selectedStatistics?.user?.array} />
                                    </div>
                                </div>

                                <div className="card card-flush h-md-250px mb-xl-10">
                                    <div className="card-header pt-5">
                                        <div className="card-title d-flex flex-column">
                                            <div className="d-flex align-items-center">
                                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                                                    {selectedStatistics?.message?.totalCount}
                                                </span>
                                                <span className={`badge ${selectedStatistics?.message?.last30DayOldPercent < 0 ? "badge-danger" : "badge-success"} fs-6 lh-1 py-1 d-flex flex-center`} style={{ height: '22px' }}>
                                                    <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                                        {
                                                            selectedStatistics?.message?.last30DayOldPercent < 0 ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.5" d="M13 14.4V3.00003C13 2.40003 12.6 2.00003 12 2.00003C11.4 2.00003 11 2.40003 11 3.00003V14.4H13Z" fill="black" />
                                                                    <path d="M5.7071 16.1071C5.07714 15.4771 5.52331 14.4 6.41421 14.4H17.5858C18.4767 14.4 18.9229 15.4771 18.2929 16.1071L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L5.7071 16.1071Z" fill="black" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                                                    <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                                                </svg>
                                                        }
                                                    </span>
                                                    {selectedStatistics?.message?.last30DayOldPercent.round()}%
                                                </span>
                                            </div>
                                            <span className="text-gray-400 pt-1 fw-bold fs-6">
                                                Mesaj grafikleri
                                            </span>
                                        </div>
                                    </div>
                                    <div className=" d-flex align-items-end card-body">
                                        <Changers data={selectedStatistics?.message?.array} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-6 mb-10 mb-xl-0">
                                <div className="card card-flush overflow-hidden w-initial " style={{ height: "533px" }}>
                                    <div className="card-header pt-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bolder text-dark">Ödemeler</span>
                                            <span className="text-gray-400 mt-1 fw-bold fs-6">Kullanıcıların yaptığı ödemeler</span>
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <AuthControl viewID={2}>
                                            <div className="d-flex align-items-center mb-2">
                                                <span className="fs-3x fw-bolder text-gray-800 me-2 lh-1 ls-n2">{selectedStatistics?.payment.totalCount}</span>
                                                <span className="fs-1 fw-bold text-gray-400 me-1 mt-n1">İşlem</span>

                                            </div>
                                            <Varben data={selectedStatistics?.payment.array} />
                                        </AuthControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AuthControl viewID={1} tittle={"Ana Sayfa Hizmet Onay Bekleyenler"}>
                            <div className="row gy-5 g-xl-10 mb-xl-10">
                                <div className="col-xl-4 mb-xl-10">
                                    <div className="card h-md-100">
                                        <div className="card-body d-flex flex-column flex-center">
                                            <div className="mb-2">
                                                <h1 className="fw-bold text-gray-800 text-center lh-lg">
                                                    Onay Bekleyen
                                                    <br />

                                                    <span className="fw-boldest"> Varmı?</span>
                                                </h1>
                                                <div
                                                    className="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-5 my-lg-12"
                                                    style={{
                                                        backgroundImage:
                                                            'url("https://preview.keenthemes.com/metronic8/demo3/assets/media/svg/illustrations/easy/2.svg")'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 mb-5 mb-xl-10">
                                    <div className="card card-flush h-xl-100">
                                        <div className="card-toolbar">
                                            <ul className="nav" id="kt_chart_widget_8_tabs">
                                                <li className="nav-item">
                                                    <a className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bolder px-4 me-1 active text-capitalize ${activeIndex === 1 ? "text-primary" : ""}`} data-bs-toggle="tab" id="kt_chart_widget_8_week_toggle" href="#kt_chart_widget_8_week_tab" onClick={() => ChangeIndex(1)}>Onay Bekleyen Hizmetler</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bolder px-4 me-1 active text-capitalize ${activeIndex === 2 ? "text-primary" : ""}`} data-bs-toggle="tab" id="kt_chart_widget_8_month_toggle" href="#kt_chart_widget_8_month_tab" onClick={() => ChangeIndex(2)}>Onay Bekleyen Hizmet Fotoğrafları</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bolder px-4 me-1 active text-capitalize ${activeIndex === 3 ? "text-primary" : ""}`} data-bs-toggle="tab" id="kt_chart_widget_8_month_toggle" href="#kt_chart_widget_8_month_tab" onClick={() => ChangeIndex(3)}>Kimlik onayı bekleyen kullanıcılar</a>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            activeIndex === 1 ? (
                                                <div>
                                                    <div className="card-header pt-7 d-flex justify-content-between align-items-center">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder text-dark text-capitalize">Onay Bekleyen hizmetler</span>
                                                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                                                                <b>{servicesList?.length}</b> Hizmet onay bekliyor
                                                            </span>
                                                        </h3>
                                                        <div className="card-toolbar">
                                                            <div className='btn btn-success btn-sm' onClick={() => ChangePage(1)}>
                                                                Tümünü Gör
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <EmptyArea dataList={servicesList?.data}>
                                                        {contentLoading ? <ContentLoader
                                                            width={550}
                                                            height="100%"
                                                            viewBox="0 100 600 500"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
                                                            <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="203" r="12" />
                                                            <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="265" r="12" />

                                                            <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="323" r="12" />
                                                            <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="386" r="12" />
                                                            <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />


                                                        </ContentLoader> :
                                                            <div className="card-body h-100" style={{ minHeight: "250px" }}>
                                                                <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer" >
                                                                    <div className="table-responsive h-100">
                                                                        <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer h-100" id="kt_table_widget_4_table" >
                                                                            <thead>
                                                                                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                                                    <th
                                                                                        className="min-w-100px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 100 }}
                                                                                    >
                                                                                        Sipariş Id
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end max-w-20px sorting_disabled"
                                                                                        style={{ width: 20 }}
                                                                                    >
                                                                                        Created
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end min-w-125px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 125 }}
                                                                                    >
                                                                                        Müşteri
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end min-w-200px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 100 }}
                                                                                    >
                                                                                        Kategori
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end min-w-50px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: "93.125px" }}
                                                                                    >
                                                                                        Durumu
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 25 }}
                                                                                    />
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="fw-bolder text-gray-600" style={{ minHeight: "250px" }}>


                                                                                {servicesList?.data?.length ? servicesList?.data?.map((service, index) => {

                                                                                    if (index < 5) return <tr className="odd" key={index}>
                                                                                        <td>
                                                                                            <NavLink className="text-dark text-hover-primary" to={"/admin/service/:" + service?.serviceID}>
                                                                                                #{service?.serviceID}
                                                                                            </NavLink>


                                                                                        </td>
                                                                                        <td
                                                                                            className="text-end"
                                                                                            data-order="2022-03-02T10:04:00+03:00"
                                                                                        >
                                                                                            {service.createDate}
                                                                                        </td>
                                                                                        <td className="text-end">
                                                                                            <a href="" className="text-dark text-hover-primary text-capitalize">
                                                                                                {service?.creatorName}
                                                                                            </a>
                                                                                        </td>
                                                                                        <td className="text-end">{service?.categoryName}</td>
                                                                                        {/* <td className="text-end">
                                                        <span className="text-dark fw-bolder">$86.70</span>
                                                    </td> */}
                                                                                        <td className='text-end' >

                                                                                            <span className={"badge fs-7 badge-light-" + (service.status === "Accepted" ? 'success' : 'danger')}>{service.verify}</span>
                                                                                        </td>


                                                                                    </tr>
                                                                                }) : ""
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <Pagination
                                                                        activePage={(parseInt(page) + 1)}
                                                                        itemsCountPerPage={(stateLimit)}
                                                                        totalItemsCount={servicesList?.totalItemCount}
                                                                        pageRangeDisplayed={5}
                                                                        itemClass={"paginate_button page-item"}
                                                                        innerClass={"pagination"}
                                                                        linkClass={"page-link"}
                                                                        activeClass={" active"}
                                                                        itemClassPrev={"previous"}
                                                                        prevPageText={<i className="previous"></i>}
                                                                        nextPageText={<i className="next"></i>}
                                                                        activeLinkClass={""}
                                                                        onChange={(e) => { ChangePagination(e) }}
                                                                    />
                                                                </div>

                                                            </div>
                                                        }
                                                    </EmptyArea>
                                                </div>
                                            ) : activeIndex === 2 ?
                                                <>
                                                    <div className="card-header pt-7 d-flex justify-content-between align-items-center">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder text-dark text-capitalize">Onay Bekleyen Hizmet Fotoğrafları</span>
                                                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                                                                <b>{photos?.length}</b> Fotoğraf onay bekliyor
                                                            </span>
                                                        </h3>
                                                        <div className="card-toolbar">
                                                            <div className='btn btn-success btn-sm' onClick={() => ChangePage(2)}>
                                                                Tümünü Gör
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <EmptyArea dataList={photos}>
                                                        {contentLoading ? <ContentLoader
                                                            width={550}
                                                            height="100%"
                                                            viewBox="0 100 600 500"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
                                                            <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="203" r="12" />
                                                            <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="265" r="12" />

                                                            <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="323" r="12" />
                                                            <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="386" r="12" />
                                                            <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />


                                                        </ContentLoader> :
                                                            <div className="card-body h-100" style={{ minHeight: "250px" }}>
                                                                <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer" >
                                                                    <div className="table-responsive h-100">
                                                                        <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer h-100" id="kt_table_widget_4_table" >
                                                                            <thead>
                                                                                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                                                    <th
                                                                                        className="min-w-100px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 100 }}
                                                                                    >
                                                                                        Servis Id
                                                                                    </th>
                                                                                    <th
                                                                                        className="min-w-200px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: "400" }}
                                                                                    >
                                                                                        Açıklama
                                                                                    </th>
                                                                                    <th
                                                                                        className="max-w-100px sorting_disabled"
                                                                                        style={{ width: "100" }}
                                                                                    >
                                                                                        Created
                                                                                    </th>
                                                                                    <th
                                                                                        className="min-w-50px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: "30px" }}
                                                                                    >
                                                                                        Durum
                                                                                    </th>
                                                                                    <th
                                                                                        className="min-w-50px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 10 }}
                                                                                    >
                                                                                        Onaylama Durumu
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="fw-bolder text-gray-600" style={{ minHeight: "250px" }}>
                                                                                {photos?.length ? photos?.map((service, index) => {

                                                                                    if (index < 5) return <tr className="odd" key={index}>
                                                                                        <td>
                                                                                            <NavLink className="text-dark text-hover-primary" to={"/admin/service/:" + service?.id}>
                                                                                                #{service?.id}
                                                                                            </NavLink>
                                                                                        </td>
                                                                                        <td>
                                                                                            <a href="" className="text-dark text-hover-primary text-capitalize">
                                                                                                {service?.description}
                                                                                            </a>
                                                                                        </td>
                                                                                        <td
                                                                                            data-order="2022-03-02T10:04:00+03:00"
                                                                                        >
                                                                                            {service.createdAt.slice(0, 10)}
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className={"badge fs-7 badge-light-" + (service.verify === "Online" ? 'success' : service.status === "Offline" ? 'danger' : 'danger')}>{service.status}</span>
                                                                                        </td>
                                                                                        <td className='' >
                                                                                            <span className={"badge fs-7 badge-light-" + (service.verify === "Approved" ? 'success' : service.verify === "Rejected" ? 'danger' : 'danger')}>{service.verify}</span>
                                                                                        </td>


                                                                                    </tr>
                                                                                }) : ""
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <Pagination
                                                                        activePage={parseInt(offset) + 1}
                                                                        itemsCountPerPage={(perPage)}
                                                                        totalItemsCount={pageCount}
                                                                        itemClass={"paginate_button page-item"}
                                                                        innerClass={"pagination"}
                                                                        linkClass={"page-link"}
                                                                        activeClass={" active"}
                                                                        itemClassPrev={"previous"}
                                                                        prevPageText={<i className="previous"></i>}
                                                                        nextPageText={<i className="next"></i>}
                                                                        activeLinkClass={"active"}
                                                                        onChange={(e) => { handlePageClick(e) }}
                                                                    />
                                                                </div>

                                                            </div>
                                                        }
                                                    </EmptyArea>
                                                </> :
                                                <>
                                                    <div className="card-header pt-7  d-flex justify-content-between align-items-center">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder text-dark  text-capitalize">Kimlik Onayı Bekleyen Kullanıcılar</span>
                                                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                                                                <b>{users?.length}</b> Kullanıcı kimliği onay bekliyor
                                                            </span>
                                                        </h3>
                                                        <div className="card-toolbar">
                                                            <div className='btn btn-success btn-sm' onClick={() => ChangePage(3)}>
                                                                Tümünü Gör
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <EmptyArea dataList={users}>
                                                        {contentLoading ? <ContentLoader
                                                            width={550}
                                                            height="100%"
                                                            viewBox="0 100 600 500"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
                                                            <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="203" r="12" />
                                                            <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="265" r="12" />

                                                            <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="323" r="12" />
                                                            <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
                                                            <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
                                                            <circle cx="1456" cy="386" r="12" />
                                                            <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
                                                            <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
                                                            <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />


                                                        </ContentLoader> :
                                                            <div className="card-body h-100" style={{ minHeight: "250px" }}>
                                                                <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer" >
                                                                    <div className="table-responsive h-100">
                                                                        <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer h-100" id="kt_table_widget_4_table" >
                                                                            <thead>
                                                                                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                                                    <th
                                                                                        className="min-w-100px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 100 }}
                                                                                    >
                                                                                        Kimlik Id
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end max-w-20px sorting_disabled"
                                                                                        style={{ width: 20 }}
                                                                                    >
                                                                                        Created
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end min-w-125px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 125 }}
                                                                                    >
                                                                                        Müşteri
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end min-w-200px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 100 }}
                                                                                    >
                                                                                        Kategori
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end min-w-50px sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: "93.125px" }}
                                                                                    >
                                                                                        Durumu
                                                                                    </th>
                                                                                    <th
                                                                                        className="text-end sorting_disabled"
                                                                                        rowSpan={1}
                                                                                        colSpan={1}
                                                                                        style={{ width: 25 }}
                                                                                    />
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="fw-bolder text-gray-600" style={{ minHeight: "250px" }}>


                                                                                {users?.length && users?.map((service, index) => {

                                                                                    if (index < 5) return <tr className="odd" key={index}>
                                                                                        <td>
                                                                                            <NavLink className="text-dark text-hover-primary" to={"/admin/service/:" + service?.serviceID}>
                                                                                                #{service?.serviceID}
                                                                                            </NavLink>
                                                                                        </td>
                                                                                        <td
                                                                                            className="text-end"
                                                                                            data-order="2022-03-02T10:04:00+03:00"
                                                                                        >
                                                                                            {service.createDate}
                                                                                        </td>
                                                                                        <td className="text-end">
                                                                                            <a href="" className="text-dark text-hover-primary text-capitalize">
                                                                                                {service?.creatorName}
                                                                                            </a>
                                                                                        </td>
                                                                                        <td className="text-end">{service?.categoryName}</td>
                                                                                        {/* <td className="text-end">
                                                        <span className="text-dark fw-bolder">$86.70</span>
                                                    </td> */}
                                                                                        <td className='text-end' >

                                                                                            <span className={"badge fs-7 badge-light-" + (service.status === "Accepted" ? 'success' : 'danger')}>{service.verify}</span>
                                                                                        </td>


                                                                                    </tr>
                                                                                })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <Pagination
                                                                        activePage={parseInt(offset) + 1}
                                                                        itemsCountPerPage={(perPage)}
                                                                        totalItemsCount={pageCount}
                                                                        itemClass={"paginate_button page-item"}
                                                                        innerClass={"pagination"}
                                                                        linkClass={"page-link"}
                                                                        activeClass={" active"}
                                                                        itemClassPrev={"previous"}
                                                                        prevPageText={<i className="previous"></i>}
                                                                        nextPageText={<i className="next"></i>}
                                                                        activeLinkClass={"active"}
                                                                        onChange={(e) => { handlePageClick(e) }}
                                                                    />
                                                                </div>

                                                            </div>
                                                        }
                                                    </EmptyArea>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div >
                        </AuthControl>
                    </div >
                </>
                : allPage === 1 ? <Page1 /> : allPage === 2 ? <Page2 /> : <Page3 />
        }

    </>
}

function ApexChart1(data) {
    var options = {
        chart: {
            id: "basic-bar"
        },

        legend: {
            show: false,
            showForSingleSeries: false,
            showForNullSeries: false,
            showForZeroSeries: false,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: undefined,
                useSeriesColors: false
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        },

        dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            formatter: function (val, opts) {
                return val
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },
        toolbar: {
            show: false,

        },
        tooltip: {
            enabled: false,
            show: false,
        }
    }

    return <>
        {
            data.data == undefined ? "" : <Chart
                options={options}
                series={data.data}
                height={"70%"}
                type="donut"
            />
        }

    </>
}

Number.prototype.round = function () {
    return +(Math.round(this + "e+" + 1) + "e-" + 1);
}
export default Dashborad