import { Route, Routes, useNavigate, Navigate } from "react-router-dom"
import Body from "../../Template/component/Body"


import Dashboard from "./Dashboard/Dashboard"
import UsersList from "./Users/UsersList"
import UserDetail from "./Users/UserDetail"
import UserInsert from './Users/UserInsert'

import { useEffect } from "react"
import Varmi from "../../Helper/Varmi"
import ServiceList from "./Services/ServiceList"
import ServiceDetail from "./Services/ServiceDetail"
import ServiceInsert from './Services/ServiceInsert'
import Reports from "./Reports/Reports"
import Paramaters from "./Settings/Paramaters"
import Conversations from "./Conversations/Conversations"
import Payments from "./Payments/Payments"
import Roles from "./Settings/Roles"
import Accounts from "./Settings/Accounts"
import Page1 from "./Dashboard/Page1"
import Page2 from "./Dashboard/Page2"
import Page3 from "./Dashboard/Page3"

export default function Admin() {

    var navigate = useNavigate();

    var navigationMenu = [
        {
            tittle: "Ana Sayfa",
            path: "/admin/home",
            authID: 100

        },
        {
            tittle: "Kullanıcılar",
            path: "/admin/user",
            authID: 110

        },
        {
            tittle: "Hizmetler",
            path: "/admin/service",
            authID: 120

        }
        ,
        {
            tittle: "Sohbetler",
            path: "/admin/conversation",
            authID: 130

        },
        {
            tittle: "Ödemeler",
            path: "/admin/payments",
            authID: 140

        },
        {
            tittle: "Raporlar",
            path: "/admin/reports",
            authID: 150
        },
        {
            tittle: "Sistem Ayarları",
            authID: 160,
            subItems: [
                {
                    tittle: "Yetki Grupları",

                    path: "/admin/roles",
                    authID: 161
                },
                {
                    tittle: "Hesaplar",
                    path: "/admin/accounts",
                    authID: 162
                },
                {
                    tittle: "Değişkenler",
                    path: "/admin/paramaters",
                    authID: 163
                },
            ]

        },
        {
            tittle: "Çıkış Yap",
            path: "/admin/log-out",
            authID: -1

        },

    ]

    var userData = {
        username: "Furkan Bahadır Özdemir",
        tittle: "Founder",
        pp: "https://preview.keenthemes.com/metronic8/demo3/assets/media/avatars/300-1.jpg"
    }

    useEffect(() => {

        if (!Varmi.isLogin) {
            navigate("/login")
        }

    }, [])

    function Router() {
        return <Routes>
            <Route path="/home" element={<Dashboard />} />
            <Route path="/home/1" element={<Page1 />} />
            <Route path="/home/2" element={<Page2 />} />
            <Route path="/home/3" element={<Page3 />} />
            <Route path="/user" element={<UsersList />} />
            <Route path="/user/:id" element={<UserDetail />} />
            <Route path="/user/insert" element={<UserInsert />} />
            <Route path="/service" element={<ServiceList />} />
            <Route path="/service/:id" element={<ServiceDetail />} />
            <Route path="/service/insert" element={<ServiceInsert />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/paramaters" element={<Paramaters />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/account" element={<Paramaters />} />
            <Route path="/conversation" element={<Conversations />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/log-out" element={<Logout />} />
            <Route
                path="*"
                element={<Navigate to="/admin/home" />}
            />
            {

                /*     <Route path="/home" element={<Dashboard />} />
        
          
        <Route path="/auth" element={<Auth />} />
        <Route path="/acc-auth" element={<AccAuth />} />
      
       
       
        <Route path="/category" element={<Category />} />*/
            }






        </Routes>
    }

    return <>
        <Body navigationMenu={navigationMenu} userData={userData} >
            <Router />
        </Body>

    </>
}

function Logout() {


    var kk = useNavigate();
    useEffect(() => {
        Varmi.setLogin(undefined)
        kk("/login")
        window.location.href = window.location.href
    })
    return <>
        asfasf</>
}