import axiosInstance from "../../Helper/axiosInstance";



export default function DBAuthorization() {

    var baseURL = process.env.REACT_APP_BASE_URL_V4
    function Login(username, password) {
        return new Promise((success, reject) => {
            axiosInstance.post(`${baseURL}/admin/auth/login`, { username: username, password: password })
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    function GetRoleList() {

        return new Promise((success, reject) => {
            axiosInstance.get(`${baseURL}/admin/roles`)
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    function ListUsers() {

        return new Promise((success, reject) => {
            axiosInstance.get(`${baseURL}/admin/roles/users`)
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    function Insert(name) {

        return new Promise((success, reject) => {
            axiosInstance.post(`${baseURL}/admin/roles/add-role`, { role_name: name, role_data: "" })
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }

    function InsertUser(namesurname, username, email, password, roleID, phone) {
        return new Promise((success, reject) => {
            axiosInstance.post(`${baseURL}/admin/auth/add-user`, {
                username: username,
                password: password,
                mail: email,
                namesurname: namesurname,
                phone: phone,
                roleID: roleID,
            })
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    function UpdateUser(id, namesurname, username, email, password, roleID, phone) {
        return new Promise((success, reject) => {
            axiosInstance.put(`${baseURL}/admin/auth/update-user/` + id, {
                username: username,
                password: password,
                mail: email,
                namesurname: namesurname,
                phone: phone,
                roleID: roleID,
            })
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    function RemoveUser(id) {
        return new Promise((success, reject) => {
            axiosInstance.delete(`${baseURL}/admin/auth/remove-user/` + id)
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    function Update(id, name, data) {



        return new Promise((success, reject) => {
            axiosInstance.put(`${baseURL}/admin/roles/` + id, { role_name: name, role_data: data })
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    function Delete(id) {

        return new Promise((success, reject) => {
            axiosInstance.delete(`${baseURL}/admin/roles/` + id)
                .then(function (response) {
                    console.log(response);
                    if (response.data.code == 1) {
                        success(response.data);
                    }
                    else {
                        reject(response.data)
                    }

                })
                .catch(function (error) {
                    return success(error);
                })
        });
    }
    return {
        GetRoleList, Insert, Delete, Update, ListUsers, Login, InsertUser, UpdateUser, RemoveUser
    }
}