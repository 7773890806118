import React from 'react'

function LoadingButton({ buttonText, loading, onClick }) {
  return (
    <button disabled={loading} type="button" className="btn btn-primary btn-lg" id="load2" onClick={() => onClick()}>
      {loading && (
        <i
          className="fa fa-refresh fa-spin"
          style={{ marginRight: "5px" }}
        />
      )}
      {loading && <span>İşlem gerçekleştiriliyor.</span>}
      {!loading && <span>{buttonText}</span>}
    </button>
  )
}

export default LoadingButton