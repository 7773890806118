import { useEffect, useState } from "react"
import { useSearchParams, useNavigate, NavLink } from "react-router-dom"
import DataCenter from "../../../Database/DataCenter"
import DBManager from "../../../Database/Manager/DBManager"
import EmptyArea from "../../../Helper/EmptyArea"
import Pagination from "react-js-pagination";
import ContentLoader from "react-content-loader"
export default function Conversations() {
    const [contentLoading, setContentLoading] = useState(false);
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(5)
    const [conversations, setConversations] = useState({});
    let history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setContentLoading(true);
        var page = searchParams.get("page");
        var limit = searchParams.get("limit");


        if (limit == null || page == null) {

            GenerateUrl()
            return;

        }
        setLimit(limit)
        setPage(page)

        GetData(page, limit)

    }, [searchParams])



    function ChangePagination(e) {

        setPage((parseInt(e) - 1))
    }
    useEffect(() => {

        var urlPage = searchParams.get("page");



        if (urlPage != null && page != urlPage && page != 0) {

            GenerateUrl();

        }



    }, [page])



    function GetData(page, limit) {
        DataCenter().Manager.Conversations.List(page, limit).then(r => (
            r !== "" ? setContentLoading(false) : "",
            setConversations(r)
        ))
    }


    function GenerateUrl() {

        var localPage = searchParams.get("page");
        var localLimit = searchParams.get("limit");



        var myArray = {
            page: page == localPage ? localPage : page,
            limit: limit == localLimit ? localLimit : limit,
        };
        var myURL = "";
        Object.keys(myArray).map(r => {

            if (r == "limit") {
                myURL += r + "=" + (myArray[r] ?? "5") + "&";
            } else if (r == "page") {
                myURL += r + "=" + (myArray[r] ?? "0") + "&";
            }
            else {
                if (myArray[r] != null) {
                    myURL += r + "=" + (myArray[r]) + "&";
                }

            }
        })

        history('/admin/conversation?' + myURL)



    }

    function ChangePageLimit(value) {
        setLimit(value)
    }


    function Find() {

        GenerateUrl()

    }



    return (
        <div>

            <div className="card card-flush overflow-hidden w-initial " >
                <div className="card-header pt-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">Sohbet Listesi</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">Sistemde kayıtlı toplam {conversations.totalItemCount} kullanıcı bulunmaktadır.</span>
                    </h3>
                    {/*end::Title*/}


                </div>


                <div className="card-body">
                    <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer" >
                        <div className="table-responsive">
                            <EmptyArea dataList={conversations?.data}>
                                {contentLoading ? <ContentLoader
                                    width={1500}
                                    height={400}
                                    viewBox="0 0 1500 400"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
                                    <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
                                    <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
                                    <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
                                    <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
                                    <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
                                    <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
                                    <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
                                    <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
                                    <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
                                    <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
                                    <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
                                    <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
                                    <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="1456" cy="203" r="12" />
                                    <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
                                    <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
                                    <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
                                    <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
                                    <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
                                    <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
                                    <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="1456" cy="265" r="12" />
                                    <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
                                    <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
                                    <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
                                    <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
                                    <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
                                    <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
                                    <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="1456" cy="323" r="12" />
                                    <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
                                    <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
                                    <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
                                    <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
                                    <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
                                    <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
                                    <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="1456" cy="386" r="12" />
                                    <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
                                    <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
                                    <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
                                    <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
                                    <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
                                    <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
                                    <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
                                    <circle cx="37" cy="97" r="11" />
                                    <rect x="26" y="23" rx="5" ry="5" width="153" height="30" />
                                    <circle cx="1316" cy="88" r="11" />
                                    <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
                                    <circle cx="77" cy="96" r="11" />
                                </ContentLoader> :
                                    <div>
                                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users"  >
                                            <thead>
                                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                                    <th
                                                        tabIndex={0}
                                                        aria-controls="kt_table_users"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                    >
                                                        ID
                                                    </th>


                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="kt_table_users"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                    >
                                                        Hizmet Bilgileri
                                                    </th>

                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="kt_table_users"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                    >
                                                        Phone
                                                    </th>
                                                    <th className="text-center " rowSpan={1} colSpan={1}>
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-gray-600 fw-bold">




                                                {conversations?.data?.length ? conversations?.data.map((user, index) => (
                                                    <tr className="odd" key={index}>
                                                        <td>{user.conversationID}</td>

                                                        <td>
                                                            <div>
                                                                <div className="fs-4 fw-bolder">
                                                                    {user.title}
                                                                </div>
                                                                <div className="fs-6 fw-bold text-gray-400">   {user.description} </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>{user.userData[1].fullName}</div>
                                                        </td>
                                                        <td>
                                                            <div>{user.userData[0].fullName}</div>
                                                        </td>
                                                        <td>
                                                            <div>{user.converstaionStatus}</div>
                                                        </td>

                                                    </tr>
                                                )) : ""}
                                            </tbody>
                                        </table>
                                        <Pagination
                                            activePage={(parseInt(page) + 1)}
                                            itemsCountPerPage={(limit)}
                                            totalItemsCount={conversations.totalItemCount}
                                            pageRangeDisplayed={5}
                                            itemClass={"paginate_button page-item"}
                                            innerClass={"pagination"}
                                            linkClass={"page-link"}
                                            activeClass={" active"}
                                            itemClassPrev={"previous"}
                                            prevPageText={<i className="previous"></i>}
                                            nextPageText={<i className="next"></i>}
                                            activeLinkClass={""}
                                            onChange={(e) => { ChangePagination(e) }}
                                        />
                                    </div>
                                }


                            </EmptyArea>

                        </div>

                    </div>





                </div>




            </div>

        </div>
    );
}