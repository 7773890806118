


import axiosInstance from "../../Helper/axiosInstance";


export default function DBParamaters() {
    var baseURL = process.env.REACT_APP_BASE_URL_V4
    function List() {

        return new Promise((success, reject) => {

            axiosInstance.get(`${baseURL}/admin/variable`)
                .then(function (response) {
                    console.log(response);
                    return success(response.data);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    function Update(key, value) {

        return new Promise((success, reject) => {

            axiosInstance.put(`${baseURL}/admin/variable/` + key, { value: value })
                .then(function (response) {
                    console.log(response);
                    return success(response);
                })
                .catch(function (error) {
                    reject(error);
                })
        });
    }
    return {
        List, Update
    }
}