import React, { Component, useContext } from "react";
import Chart from "react-apexcharts";

class Varben extends Component {
    constructor(props) {
        super(props);

        this.success = {
            options: {
                chart: {
                    sparkline: {
                        enabled: false // only lines will be shown
                    },
                    height: 350,
                    type: 'area',
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 20,
                        left: 0,
                        blur: 4,
                        color: '#000',
                        opacity: 0.05
                    },
                    toolbar: {
                        show: false // top-right icons 
                    },

                },
                grid: {
                    strokeDashArray: 3,
                },
                colors: ['#FFB703', '#8ECAE6'],
                fill: {
                    type: 'solid',
                    opacity: [0.15, 1],
                },
                dataLabels: {
                    enabled: false
                },

                stroke: {
                    curve: 'smooth'
                },
                labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            },
            series: [{
                name: 'Total',
                data: [1, 2, 3, 4, 5, 6],
                type: 'area',

            }, {
                name: 'Online',
                data: [1, 2, 3, 4, 5, 6],
                type: 'line',
            }],
        };
    }


    render() {
        return (
            <>

                <Chart
                    options={this.success.options}
                    series={this.success.series}
                    type="area"
                />
            </>

        );
    }
}

export default Varben;