function Varmi() {
    var baseURL = "";

    function isLogin() {
        if (GetProfile().id == undefined)
            return false
        else
            return true;
    }

    function GetProfile() {
        var item = localStorage.getItem("varmi_user_data")
        if (item == null || item == undefined || item == "" || item == "undefined") {
            return {}
        }
        else {
            return JSON.parse(item)
        }
    }

    function profileClicker(aa)
    {
        global.profileClicker = aa;
    }

    function openProfile()
    {
        global.profileClicker();
    }
    function setLogin(data) {
        localStorage.setItem("varmi_user_data", data)
    }
    return {
        isLogin: isLogin(),
        setLogin: setLogin,
        getProfile: GetProfile,
        profileClicker : profileClicker,
        openProfile : openProfile,
    }
}

export default Varmi()