import axiosInstance from "../../Helper/axiosInstance";


export default function DBReport() {
  function List(id, beginDate, endDate) {
    return new Promise((success, reject) => {
      axiosInstance.get(`${process.env.REACT_APP_BASE_URL_V4}/admin/statistics/${id}/${endDate}/${beginDate}`)
        .then(function (response) {
          console.log(response);
          success(response.data)
        })
        .catch(function (error) {
          reject(error);
        })
    });
  }
  // function Delete(id, userID) {
  //   return new Promise((success, reject) => {
  //     axiosInstance.delete(`${Cactus().baseURL}manager/products/delete-product/${id}/${userID}`)
  //       .then(function (response) {
  //         success(response)
  //       })
  //       .catch(function (error) {
  //         reject(error)
  //       })
  //   });
  // }

  // function Detail(id, lang) {
  //   return new Promise((success, reject) => {
  //     axiosInstance.get(`${Cactus().baseURL}manager/products/${id}/${lang}`)
  //       .then(function (response) {
  //         success(response?.data)
  //       })
  //       .catch(function (error) {
  //         reject(error)
  //       })
  //   });
  // }

  // function Insert(data) {
  //   let stock = JSON.parse(data?.stock);
  //   let price = JSON.parse(data?.price)
  //   var formData = new FormData();
  //   formData.append("name", JSON.stringify(data?.productName));
  //   formData.append("description", JSON.stringify(data?.description));
  //   formData.append("price", price);
  //   formData.append("stock", stock);
  //   // formData.append("creatorId", data?.creatorId);
  //   formData.append("businessId", data?.businessId);
  //   formData.append("isDigital", data.isDigital);
  //   formData.append("tester", data?.tester);
  //   formData.append("codeDelivery", data?.isCodeDelivery);
  //   formData.append("file", data?.photoPath);

  //   return new Promise((success, reject) => {
  //     axiosInstance.post(`${Cactus().baseURL}manager/products/create-product`, formData, {
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  //       },
  //     }).then(function (response) {
  //       success(response.data)
  //     })
  //       .catch(function (error) {
  //         reject(error.data)
  //       })

  //   })
  // }
  // function Update(data) {
  //   let stock = JSON.parse(data?.stock);
  //   let price = JSON.parse(data?.price)
  //   var formData = new FormData();
  //   formData.append("name", JSON.stringify(data?.productName));
  //   formData.append("description", JSON.stringify(data?.description));
  //   formData.append("price", price);
  //   formData.append("stock", stock);
  //   formData.append("creatorId", data?.creatorId);
  //   formData.append("id", data?.businessId);
  //   formData.append("tester", data?.tester);
  //   formData.append("codeDelivery", data?.isCodeDelivery);
  //   formData.append("file", data?.photoPath);
  //   return new Promise((success, reject) => {
  //     axiosInstance.put(`${Cactus().baseURL}manager/products/`, formData, {
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     }).then(function (response) {
  //       success(response.data)
  //     })
  //       .catch(function (error) {
  //         reject(error.data)
  //       })

  //   })
  // }

  return {
    List
  }
}