import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import Pagination from 'react-js-pagination'
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';
import DataCenter from '../../../Database/DataCenter';
import EmptyArea from '../../../Helper/EmptyArea'

function Page2() {
  var navigate = useNavigate()
  const [contentLoading, setContentLoading] = useState(false);
  const [pendingPhotos, setPendingPhotos] = useState([]);


  //States for pagination
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState([]);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(70)

  useEffect(() => {
    let mounted = true;
    setContentLoading(true);
    DataCenter().Manager.Home.PendingList().then(res => {
      if (res !== "" && mounted) {
        setContentLoading(false);
        setPendingPhotos(res.pendingServiceImage);
      }
    })
    return () => {
      mounted = false;
    }
  }, []);

  function getData() {
    const slice = pendingPhotos?.slice((offset * perPage), (offset * perPage) + perPage);
    setData([...slice]);
    setPageCount(pendingPhotos?.length);
  }

  useEffect(() => {
    console.log(offset);
    getData();
  }, [offset]);

  const handlePageClick = (e) => {
    setOffset(e - 1)
    // navigate("/admin/home/2?fromService=" + (e - 1));
  };

  // useEffect(() => {
  //   setContentLoading(true);
  //   var fromService = searchParams.get("fromService");

  //   setOffset(fromService);

  //   if (fromService == null) {
  //     navigate("/admin/home/2?fromService=" + (fromService ?? 0))
  //     return ""
  //   }
  // }, [searchParams])

  useEffect(() => {
    console.log(pendingPhotos);
  }, [pendingPhotos]);
  return (
    <div className='card card-flush h-xl-100'>
      <div className="card-header pt-7 d-flex justify-content-between align-items-center">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark text-capitalize">Onay Bekleyen Hizmet Fotoğrafları</span>
          <span className="text-gray-400 mt-1 fw-bold fs-6">
            <b>{pendingPhotos.length}</b> Fotoğraf onay bekliyor
          </span>
        </h3>
      </div>
      <EmptyArea dataList={data}>
        {
          contentLoading ? <ContentLoader
            width={850}
            height="100%"
            viewBox="0 100 600 500"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
            <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
            <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
            <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
            <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
            <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
            <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
            <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
            <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
            <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
            <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
            <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
            <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
            <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
            <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
            <circle cx="1456" cy="203" r="12" />
            <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
            <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
            <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
            <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
            <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
            <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
            <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
            <circle cx="1456" cy="265" r="12" />

            <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
            <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
            <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
            <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
            <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
            <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
            <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
            <circle cx="1456" cy="323" r="12" />
            <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
            <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
            <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
            <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
            <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
            <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
            <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
            <circle cx="1456" cy="386" r="12" />
            <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
            <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
            <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
            <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
            <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
            <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
            <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
            <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
            <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
            <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
            <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />


          </ContentLoader> :
            <div className="card-body h-100" style={{ minHeight: "250px" }}>
              <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer" >
                <div className="table-responsive h-100">
                  <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer h-100" id="kt_table_widget_4_table" >
                    <thead>
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th
                          className="min-w-100px sorting_disabled"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: 100 }}
                        >
                          Servis Id
                        </th>
                        <th
                          className="min-w-200px sorting_disabled"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "400" }}
                        >
                          Açıklama
                        </th>
                        <th
                          className="max-w-100px sorting_disabled"
                          style={{ width: "100" }}
                        >
                          Created
                        </th>
                        <th
                          className="min-w-50px sorting_disabled"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "30px" }}
                        >
                          Durum
                        </th>
                        <th
                          className="min-w-50px sorting_disabled"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: 10 }}
                        >
                          Onaylama Durumu
                        </th>
                      </tr>
                    </thead>
                    <tbody className="fw-bolder text-gray-600" style={{ minHeight: "250px" }}>
                      {data?.length ? data?.map((service, index) => {
                        return <tr className="odd" key={index}>
                          <td>
                            <NavLink className="text-dark text-hover-primary" to={"/admin/service/:" + service?.id}>
                              #{service?.id}
                            </NavLink>
                          </td>
                          <td>
                            <a href="" className="text-dark text-hover-primary text-capitalize">
                              {service?.description}
                            </a>
                          </td>
                          <td
                            data-order="2022-03-02T10:04:00+03:00"
                          >
                            {service.createdAt.slice(0, 10)}
                          </td>
                          <td>
                            <span className={"badge fs-7 badge-light-" + (service.verify === "Online" ? 'success' : service.status === "Offline" ? 'danger' : 'danger')}>{service.status}</span>
                          </td>
                          <td className='' >
                            <span className={"badge fs-7 badge-light-" + (service.verify === "Approved" ? 'success' : service.verify === "Rejected" ? 'danger' : 'danger')}>{service.verify}</span>
                          </td>


                        </tr>
                      }) : ""
                      }
                    </tbody>
                  </table>
                </div>
                <Pagination
                  activePage={parseInt(offset) + 1}
                  itemsCountPerPage={(perPage)}
                  totalItemsCount={pageCount}
                  itemClass={"paginate_button page-item"}
                  innerClass={"pagination"}
                  linkClass={"page-link"}
                  activeClass={" active"}
                  itemClassPrev={"previous"}
                  prevPageText={<i className="previous"></i>}
                  nextPageText={<i className="next"></i>}
                  activeLinkClass={"active"}
                  onChange={(e) => { handlePageClick(e) }}
                />

              </div>

            </div>
        }
      </EmptyArea>
    </div>
  )
}

export default Page2