import axiosInstance from "../../Helper/axiosInstance";



export default function DBUser() {
  function List(from, limit, name, beginDate, endDate, phone, profileType) {

    var url = "";

    if (name != null) {
      url += "name=" + name + "&";
    }
    if (phone != null) {
      url += "phone=" + phone + "&";
    }
    if (profileType != null) {
      url += "profileType=" + profileType + "&";
    }

    if (url.length != 0)
      url = url.substring(0, url.length - 1)



    var baseURL = process.env.REACT_APP_BASE_URL_V4
    return new Promise((success, reject) => {
      axiosInstance.get(`${baseURL}/admin/user/${from}/${limit}?` + url)
        .then(function (response) {
          return success(response?.data);
        })
        .catch(function (error) {
          reject(error);
        })
    });
  }
  function Detail(id) {
    var baseURL = process.env.REACT_APP_BASE_URL_V4
    return new Promise((success, reject) => {
      axiosInstance.get(`${baseURL}/admin/user/${id}`)
        .then(function (response) {
          return success(response?.data);
        })
        .catch(function (error) {
          reject(error);
        })
    });
  }

  function Authentication(id, status) {
    var baseURL = process.env.REACT_APP_BASE_URL_V4
    return new Promise((success, reject) => {
      axiosInstance.put(`${baseURL}/admin/user/${id}/${status}`)
        .then(function (response) {
          return success(response?.data);
        })
        .catch(function (error) {
          reject(error);
        })
    });
  }

  return {
    List, Detail, Authentication
  }
}