import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import styles from "../../../Template/css/checkbox.css"
import DBManager from "../../../Database/Manager/DBManager";
import LoadingButton from "../../../Template/Helper/LoadingButton";
import Swal from 'sweetalert2'
import Alert from '../../../Template/Helper/Alert'

export default function Roles() {
    const [loading, setLoading] = useState(false);
    const yetkiAlanları = [
        { title: "Admin", color: "success" },
        { title: "Developer", color: "danger" },
        { title: "Accounter", color: "info" },
        { title: "Mobil", color: "success" },
        { title: "Game", color: "warning" },

    ];
    const yetkiler = [
        { title: "Silme" },
        { title: "Ekleme" },
        { title: "Güncelleme" },
        { title: "Deneme" },
        { title: "Deneme2" },
        { title: "Deneme3" },
    ]



    const [isActive, setActive] = useState(false)

    let inputGroupName = useRef(null);

    const [roleList, setRoleList] = useState([])
    const [permissionList, setPermissionList] = useState([
        {
            key: 100,
            tittle: "Sol Menü Ana Saufa"
        },
        {
            key: 110,
            tittle: "Sol Menü Kullanıcılar"
        },
        {
            key: 120,
            tittle: "Sol Menü Hizmetler"
        },
        {
            key: 130,
            tittle: "Sol Menü Sohbetler"
        },
        {
            key: 140,
            tittle: "Sol Menü Ödemeler"
        },
        {
            key: 150,
            tittle: "Sol Menü Raporlar"
        },
        {
            key: 160,
            tittle: "Sol Menü Sistem Ayarları"
        }, {
            key: 161,
            tittle: "Sistem ayarları > Yetki Grupları"
        },{
            key: 162,
            tittle: "Sistem ayarları > Hesaplar"
        },{
            key: 163,
            tittle: "Sistem ayarları > Parametreler"
        },
    ])

    function InsertGroup() {
        if (inputGroupName.current.value !== "") {
            setLoading(true);
            DBManager().Authorization.Insert(inputGroupName.current.value).then(r => {
                setActive(false)
                DBManager().Authorization.GetRoleList().then(r => (
                    r.code === 1 ? (
                        setLoading(false),
                        setRoleList(r.data),
                        Swal.fire(
                            {
                                icon: "success",
                                text: "İşlem başarılı"
                            }
                        )
                    ) : ""


                ))
            })
        } else {
            Alert().Fail("Lütfen bir yetki grubu adı değeri giriniz");
        }
    }

    function Update(roleID, permissionKey, checked) {

        var index = roleList.findIndex((e) => { return e.id == roleID })
        var dataArray = roleList[index].role_data.split(",");
        var roleDataIndex = dataArray.findIndex((e) => { return e == permissionKey })
        if (roleDataIndex == -1 && checked) {
            dataArray.push(permissionKey)
        }
        else {
            dataArray.splice(roleDataIndex, 1)
        }
        roleList[index].role_data = dataArray.join(",")
        DBManager().Authorization.Update(roleID, roleList[index].role_name, roleList[index].role_data).then(r => {
            setActive(false)
            DBManager().Authorization.GetRoleList().then(r => {
                setRoleList(r.data);
            })
        })

    }
    function RemoveGroup(id) {
        DBManager().Authorization.Delete(id).then(r => {
            setActive(false)
        })
    }

    useEffect(() => {
        DBManager().Authorization.GetRoleList().then(r => {
            setRoleList(r.data);
        })
    }, [])

    useEffect(() => {
        console.log(roleList);
    }, [roleList]);

    return (
        <div>
            <div className="card mb-7">
                <div className="card-header pt-5" style={{ border: "none" }}>
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">Yetki Grupları</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">Sistem içerisindeki yetki grupları bu ekrandan belirlenir.</span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                            <div className="btn btn-primary btn-sm" onClick={() => { setActive(true) }}>
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="black" />
                                        <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                                    </svg>
                                </span>Yetki Grubu Oluştur</div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive table-lg">
                        <table className="table  mb-0">
                            <thead>
                                <tr >
                                    <th className="text-center" ></th>
                                    {roleList.length && roleList.map((item, index) => {
                                        return <th className="text-center" key={index}  >
                                            <span className={`badge text-black`} onClick={() => { RemoveGroup(item.id) }}>{item.role_name}</span>
                                        </th>
                                    }
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {permissionList.length && permissionList.map((yetki, index) => {
                                    return <tr key={index}>
                                        <td className="text-left align-middle" > <div >{yetki.tittle}</div></td>
                                        {roleList.length && roleList.map((item, index) => {
                                            return <td className="text-center align-middle">
                                                <div className="center">
                                                    <label className="label">
                                                        <input className="label__checkbox" checked={item.role_data.split(",").findIndex((e) => { return e == yetki.key }) != -1} onChange={(e) => { Update(item.id, yetki.key, e.target.checked) }} type="checkbox" name={yetki.tittle} />
                                                        <span className="label__text">
                                                            <span className="label__check">
                                                                <i className="fa fa-check icon"></i>
                                                            </span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        }
                                        )}
                                    </tr>
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className={"modal fade " + (isActive ? "show" : "")} style={{ display: (isActive ? "block" : "none"), background: "#00000045" }} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Yeni bir yetki grubu oluştur</h5>
                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <span className="svg-icon svg-icon-2x" />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div id="kt_advanced_search_form">
                                <div className="row g-8">
                                    <div className="col-lg-12">
                                        <label className="fs-6 form-label fw-bolder text-dark">
                                            Yetki Grubunun Adı
                                        </label>
                                        <input
                                            ref={inputGroupName}
                                            type="text"
                                            className="form-control form-control form-control-solid"
                                            name="city"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={() => { setActive(false) }}>Close</button>
                            <LoadingButton buttonText="Oluştur" loading={loading} onClick={InsertGroup} />
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}
