import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import DBManager from "../../../Database/Manager/DBManager";
import LoadingButton from "../../../Template/Helper/LoadingButton";
import Alert from '../../../Template/Helper/Alert'
import DataCenter from "../../../Database/DataCenter";


export default function Accounts() {
    const [loading, setLoading] = useState(false);
    const list = [
        {
            id: "1",
            name: "Neim Ramazanoğlu",
            mail: "mr.ramazanoglu@gmail.com",
            phone: "0536-998-54-58",
            agreement: "true"
        },
        {
            id: "2",
            name: "Zeynep Yıldırım",
            mail: "zeynep@gmail.com",
            phone: "0536-123-45-67",
            agreement: "false"
        },
        {
            id: "3",
            name: "Sümeyye Yeğen ",
            mail: "sümeyye@gmail.com",
            phone: "0536-234-43-345",
            agreement: "true"
        }
    ]


    const [userList, setUserList] = useState([])
    const [roleList, setRoleList] = useState([])
    const [isActive, setActive] = useState(false)
    const [isUpdate, setUpdate] = useState(false)
    const [selectedID, setSelectedID] = useState(-1)


    let inputNameSurname = useRef();
    let inputUsername = useRef();
    let inputPassword = useRef();
    let inputEmail = useRef();
    let inputPhone = useRef();
    let inputRole = useRef();
    function InsertGroup() {
        setLoading(true);
        DBManager().Authorization.InsertUser(inputNameSurname.current.value, inputUsername.current.value, inputEmail.current.value, inputPassword.current.value, inputRole.current.value, inputPhone.current.value).then(r => {
            console.log(r);
            setActive(false);
            DBManager().Authorization.ListUsers().then(r => (
                r.code === 1 ? (
                    setLoading(false),
                    setUserList(r.data),
                    Swal.fire(
                        {
                            icon: "success",
                            text: "İşlem başarılı"
                        }
                    )
                ) : ""
            ))
        })
    }

    function RemoveAccount(id) {
        Alert().Question("Silmek istediğinize emin misiniz?").then(res => {
            if (res) {
                DataCenter().Manager.Authorization.RemoveUser(id).then(r => {
                    DataCenter().Manager.Authorization.ListUsers().then(r => {
                        console.log(r);
                        if (r.code === 1) {
                            setUserList(r.data)
                            Alert().Success("İşlem başarılı");
                        }
                    })
                })
            }
        })

    }
    function OpenUpdatePanel(data) {
        setUpdate(true);
        setActive(true);
        setSelectedID(data.adminid)
        inputNameSurname.current.value = data.name_surname;
        inputUsername.current.value = data.username;
        inputPassword.current.value = data.password;
        inputEmail.current.value = data.email;
        inputPhone.current.value = data.phone;
        inputRole.current.value = data.roles;
    }
    function OpenInsertPanel() {
        setUpdate(false);
        setActive(true);
        inputNameSurname.current.value = ""
        inputUsername.current.value = ""
        inputPassword.current.value = ""
        inputEmail.current.value = ""
        inputPhone.current.value = ""
        inputRole.current.value = ""
    }

    function InsertGroup() {

        if (inputNameSurname.current.value !== "") {
            if (inputUsername.current.value !== "") {
                if (inputRole.current.value !== "") {
                    if (inputEmail.current.value !== "") {
                        if (inputPhone.current.value !== "") {
                            if (inputPassword.current.value !== "") {
                                setLoading(true);
                                DBManager().Authorization.InsertUser(inputNameSurname.current.value, inputUsername.current.value, inputEmail.current.value, inputPassword.current.value, inputRole.current.value, inputPhone.current.value).then(r => {
                                    console.log(r);
                                    setActive(false);
                                    DBManager().Authorization.ListUsers().then(r => (
                                        r.code === 1 ? (
                                            setLoading(false),
                                            setUserList(r.data),
                                            Swal.fire(
                                                {
                                                    icon: "success",
                                                    text: "İşlem başarılı"
                                                }
                                            )
                                        ) : ""
                                    ))
                                })
                            }
                            else {
                                Alert().Fail("Lütfen şifre giriniz");
                            }
                        }
                        else {
                            Alert().Fail("Lütfen bir telefon numarası giriniz");
                        }
                    } else {
                        Alert().Fail("Lütfen bir email giriniz");
                    }
                }
                else {
                    Alert().Fail("Lütfen bir yetki türü seçiniz");
                }
            } else {
                Alert().Fail("Lütfen kullanıcı adınızı giriniz");
            }
        }
        else {
            Alert().Fail("Lütfen adınızı ve soyadınızı giriniz");
        }
    }
    function UpdateAccount() {
        setLoading(true);
        DBManager().Authorization.UpdateUser(selectedID, inputNameSurname.current.value, inputUsername.current.value, inputEmail.current.value, inputPassword.current.value, inputRole.current.value, inputPhone.current.value).then(r => {
            setActive(false);
            DBManager().Authorization.ListUsers().then(r => (
                r.code === 1 ? (
                    setLoading(false),
                    setUserList(r.data),
                    Swal.fire(
                        {
                            icon: "success",
                            text: "İşlem başarılı"
                        }
                    )
                ) : ""
            ))
        })
    }
    useEffect(() => {
        DBManager().Authorization.ListUsers().then(r => {
            setUserList(r.data)
        })
        DBManager().Authorization.GetRoleList().then(r => {
            setRoleList(r)
        })
    }, [])
    return <div>
        <div className="card">


            <div className="card-header pt-5" style={{ border: "none" }}>
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-dark">Yetkili Hesaplar</span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">Sistem içerisindeki tanımlanmış olan hesaplar listelenir.</span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                    {/*begin::Toolbar*/}
                    <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                        {/*begin::Filter*/}



                        <button type="button" className="btn btn-primary btn-sm" onClick={() => { OpenInsertPanel() }}>
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                            <span className="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="black" />
                                    <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}Yetki Bir Hesap Oluştur</button>
                        {/*end::Add user*/}
                    </div>





                </div>

            </div>
            <div className="card-body py-4">
                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                    <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >ID</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Photo</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Name</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Mail</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Phone</th>
                            <th className="sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} >Agreement</th>
                            <th className="text-center " rowSpan={1} colSpan={1} >Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-bold">
                        {userList.map((user, index) => (
                            <tr className="odd" key={index}>
                                <td>
                                    {user.adminid}
                                </td>
                                <td className="d-flex align-items-center">
                                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                        <a href="">
                                            <div className="symbol-label">
                                                <img src={require("../../../Assets/logo/varmi-logo.png")} alt="Emma Smith" className="w-100" />
                                            </div>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-column">
                                        <div className="fs-3 fw-bolder text-dark">  {user.name_surname}</div>
                                        <div className="text-gray-400 fw-bold fs-5 mt-1 mb-7">  {user.username}</div>


                                    </div>
                                </td>
                                <td>{user.email}</td>
                                <td >
                                    <div >{user.phone}</div>
                                </td>
                                <td >
                                    <div className={"badge py-3 px-4 fs-7 badge-light-success"}>{user.role_name}</div>
                                </td>
                                <td className="text-center">
                                    <div className="btn btn-light-danger btn-sm" onClick={() => { RemoveAccount(user.adminid) }}>Sil</div>
                                    <div className="btn btn-primary btn-sm mx-2" onClick={() => { OpenUpdatePanel(user) }}>Güncelle</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>


                </div>
            </div>
        </div>

        <div className={"modal fade " + (isActive ? "show" : "")} style={{ display: (isActive ? "block" : "none"), background: "#00000045" }} id="kt_modal_1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Yeni bir yetki grubu oluştur</h5>
                        {/*begin::Close*/}
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                            <span className="svg-icon svg-icon-2x" />
                        </div>
                        {/*end::Close*/}
                    </div>
                    <div className="modal-body">
                        <div id="kt_advanced_search_form">


                            <div className="row g-8">


                                <div className="col-lg-12">
                                    <label className="fs-6 form-label fw-bolder text-dark">
                                        Yetkili İsim - Soyisim
                                    </label>
                                    <input
                                        ref={inputNameSurname}
                                        type="text"
                                        className="form-control form-control form-control-solid"
                                        name="city"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label className="fs-6 form-label fw-bolder text-dark">
                                        Yetkili Kullanıcı Adı
                                    </label>
                                    <input
                                        ref={inputUsername}
                                        type="text"
                                        className="form-control form-control form-control-solid"
                                        name="city"
                                    />
                                </div>
                                <div className="col-lg-12">



                                    <label className="fs-6 form-label fw-bolder text-dark">
                                        Yetki Grubu
                                    </label>

                                    <select
                                        className="form-select form-select-solid "
                                        data-control="select2"
                                        data-placeholder="In Progress"
                                        data-hide-search="true"
                                        ref={inputRole}
                                        data-select2-id="select2-data-10-6uic"
                                        tabIndex={-1}
                                        aria-hidden="true"
                                    >
                                        {
                                            roleList?.data?.map(it => {
                                                return <option value={it.id}>{it.role_name}</option>
                                            })
                                        }



                                    </select>


                                </div>
                                <div className="col-lg-12">
                                    <label className="fs-6 form-label fw-bolder text-dark">
                                        Yetkili Mail
                                    </label>
                                    <input
                                        ref={inputEmail}
                                        type="text"
                                        className="form-control form-control form-control-solid"
                                        name="city"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label className="fs-6 form-label fw-bolder text-dark">
                                        Yetkili Şifre
                                    </label>
                                    <input
                                        ref={inputPassword}
                                        type="text"
                                        className="form-control form-control form-control-solid"
                                        name="city"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label className="fs-6 form-label fw-bolder text-dark">
                                        Yetkili Telefon
                                    </label>
                                    <input
                                        ref={inputPhone}
                                        type="text"
                                        className="form-control form-control form-control-solid"
                                        name="city"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={() => { setUpdate(false); setActive(false) }}>Close</button>
                        {
                            isUpdate ? <LoadingButton buttonText="Güncelle" loading={loading} onClick={UpdateAccount} /> : <LoadingButton buttonText="Oluştur" loading={loading} onClick={InsertGroup} />
                        }

                    </div>
                </div>
            </div>
        </div>


    </div>
}