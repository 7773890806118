import React, { useState } from 'react'
import Chart from "react-apexcharts";
import DataCenter from '../../../Database/DataCenter';
import LoadingButton from '../../../Helper/LoadingButton';
import Alert from '../../../Template/Helper/Alert';

function ServiceOverview({ serviceDetail, userDetail, imageData }) {
  const [loading, setLoading] = useState(false);
  function PhotoConfirmation(id, status) {
    Alert().Question("Onaylamak istediğinize emin misiniz?").then(res => {
      if (res) {
        setLoading(true);
        DataCenter().Manager.Services.PhotoConfirmation(id, status).then(res => {
          setLoading(false);
          Alert().Success("Güncelleme işlemi başarılı")
        })
      }
    })
  }

  function PhotoRejection(id, status) {
    Alert().Question("Reddetmek istediğinize emin misiniz?").then(res => {
      if (res) {
        setLoading(true);
        DataCenter().Manager.Services.PhotoConfirmation(id, status).then(res => {
          setLoading(false);
          Alert().Success("Güncelleme işlemi başarılı")
        })
      }
    })
  }
  return (
    <div className="row g-6 g-xl-9">
      <div className="col-lg-6"></div>
      {/*<div className="card card-flush h-lg-100">
          <div className="card-header mt-6">
            <div className="card-title flex-column">
              <h3 className="fw-bolder mb-1">Kimlik Doğrulama</h3>
            </div>
            <div className="card-toolbar">

            </div>
          </div>
          <div className="card-body">
            <div
              className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded"
              style={{
                width: "20rem",
                height: "10rem",
                background:
                  'url("' + (serviceDetail?.authFilePath === null || serviceDetail?.authFilePath === undefined ? "https://cdn2.limonhost.net/wp-content/uploads/2020/10/404-not-found-sayfa-bulunamadi-hatasi-ve-cozumu.png" : (process.env.REACT_APP_IMAGE + "/" + serviceDetail?.authFilePath)) + '") center center no-repeat',
                objectFit: "contain"
              }}
            />
            <h5 className='fw-bolder'>{serviceDetail?.authFileName === null ? "Test" : serviceDetail?.authFileName}</h5>
            <div className='btn btn-success btn-sm'>Onayla</div>
          </div>
        </div>*/}
      <div className="col-lg-6">
        <div className="card card-flush py-4 flex-row-fluid">
          <div className="card-header">
            <div className="card-title">
              <h2>Hizmeti Oluşturan Kişi Bilgileri</h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div
              className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
              style={{
                width: "15rem",
                height: "20rem",
                background: 'url("' + (serviceDetail?.creatorImage === null || serviceDetail?.creatorImage === undefined ? "https://cdn2.limonhost.net/wp-content/uploads/2020/10/404-not-found-sayfa-bulunamadi-hatasi-ve-cozumu.png" : (process.env.REACT_APP_IMAGE + "/" + serviceDetail?.creatorImage)) + '") center center no-repeat',
                objectFit: "contain"
              }}
            />
            <h5 className='fw-bolder mt-3'>{serviceDetail?.creatorName}</h5>
          </div>
        </div>

      </div>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='card-body'>
            <div className="row g-10">
              {
                imageData?.length ? imageData.map(it => {
                  console.log(it);
                  return <div className="col-md-4">
                    <div className="card-xl-stretch me-md-6">
                      <div
                        className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
                        style={{
                          background:
                            'url("' + (it == null && it === [] ? "https://cdn2.limonhost.net/wp-content/uploads/2020/10/404-not-found-sayfa-bulunamadi-hatasi-ve-cozumu.png" : ("https://3.72.47.28:3000/" + it.path)) + '") center center no-repeat',
                          objectFit: 'contain'
                        }}
                      />
                      <div className='d-flex mt-5 justify-content-between'>
                        <LoadingButton buttonText="Onayla" className="btn btn-success btn-sm w-100 me-2" loading={loading} id={serviceDetail?.serviceID} status="Approved" onClick={PhotoConfirmation} />
                        <LoadingButton buttonText="Reddet" className="btn btn-danger btn-sm w-100" loading={loading} id={serviceDetail?.serviceID} status="Rejected" onClick={PhotoRejection} />
                        {/* <button className='btn btn-danger btn-sm w-100' onClick={() => PhotoRejection(it.id, "Rejected")}>Reddet</button> */}
                      </div>
                    </div>
                  </div>
                }) : ""
              }



            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

/**
 * 
 * @param {Object} props 
 * @param {Object} props.data 
 * @returns 
 */
function ApexChart1(props) {
  var options = {
    colors: ['#009ef7', '#50cd89', '#f1416c'],
    chart: {
      id: "basic-bar"
    },

    legend: {
      show: false,
      showForSingleSeries: false,
      showForNullSeries: false,
      showForZeroSeries: false,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0
      },
      onItemClick: {
        toggleDataSeries: true
      },
      onItemHover: {
        highlightDataSeries: true
      },
    },

    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return val
      },
      textAnchor: 'middle',
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: undefined
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#fff',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45
      }
    },
    toolbar: {
      show: false,

    },
    tooltip: {
      enabled: false,
      show: false,
    }
  }
  var series = [44, 66, 55];
  return <>
    {
      props.data == null ? "" : <Chart
        options={options}
        series={props.data}
        height={"100%"}
        type="donut"
      />
    }

  </>
}

export default ServiceOverview